import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, LinearProgress, Button, Link, Dialog, DialogTitle, DialogContent, Typography, FormLabel }  from '@material-ui/core';
import { SERVER_URL } from '../api';
import {useDispatch, useSelector} from 'react-redux';
import DropdownAutocomplete from './DropdownAutocomplete';
import * as Actions from "../redux/actions";

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: 'white',
        backgroundColor: '#1274E4',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#1060BB'
        },
    },
}))(Button);

const WhiteStyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: '#1274E4',
        textTransform: 'none',
        backgroundColor: 'white',
        border: '1px solid #1274E4',
        '&:hover': {
            backgroundColor: 'rgba(240, 240, 240, 0.8)'
        },
    },
}))(Button);

const BluishTextField = withStyles({
    root: {
        margin: '4px 0px',
        backgroundColor: 'rgba(241, 246, 252, 1)',
        width: '100%',
        '& input, textarea': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            fontFamily: 'Assistant',
            fontSize: '12px',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#505050',
        },
        '& input::placeholder': {
            fontFamily: 'Assistant',
            fontSize: '12px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#565656'
        },
        '& label.Mui-focused': {
            color: '#4789d2',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#4789d2',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(208, 208, 208, 1)',
        },
        '& .MuiOutlinedInput-input': {
            padding: '9px 8px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(238, 238, 238, 1)',
                borderRadius: '4px',
            },
            '&:hover fieldset': {
                borderColor: '#808080',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#4789d2',
            },
        },
    },
})(TextField)

const useStyles = makeStyles(theme => ({
    root: {
    },
    paper: {
        margin: '0px',
        width: '400px',
        height: '520px',
        overflowY: 'auto'
    },
    title: {
        fontFamily: 'Assistant',
        color: 'white',
        backgroundColor: theme.palette.primary[50],
        height: '28px',
        '& > .MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 700,
            color: theme.palette.secondary[700]
        }
    },
    secondary: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        letterSpacing: '0px',
        margin: '10px 0px',
        color: '#35373D'
    },
    remark: {
        fontSize: '10px',
        fontFamily: 'Assistant',
        color: 'rgba(60, 60, 60, 0.5)'
    },
    button: {
        width: '100%',
        margin: '13px 0px',
        padding: '0 15px',
        height: '38px',
        borderRadius: '25px',
    },
    button_new_contact:{
        margin: '0 0 0 15px',
        height: '30px',
        borderRadius: '25px',
    },
    label: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0px',
        color: '#35373D'
    },
    link: {
        fontSize: '13px',
        fontWeight: 400,
        color: '#57606B'
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin : '24px 0px'
    },
    preview: {
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Assistant',
        letterSpacing: '0px',
        color: '#1274E4'
    },
    explanation: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '11px',
        letterSpacing: '0px',
        color: '#57606B',
        margin: '6px 0px'
    }
}));

export default function GenerateDocumentDialog(props) {
    const classes = useStyles();
    const {open, onClose, onShare, onGenerate, documentId} = props;
    const [docName, setDocName] = useState(undefined);
    const [sentTo, setSentTo] = useState(undefined);
    const [page, setPage] = useState(0);
    const [wizardUrl, setWizardUrl] = useState('');
    const loadingPhase = useSelector(state => state.main.loadingPhase);
    const saveContact = useSelector(state => state.main.saveContact);
    const wizardId = useSelector(state => state.questionnaire.wizardId);

    let documentViewUrl = new URL(`${SERVER_URL}/documents/${documentId}`);
    let params = new URLSearchParams(documentViewUrl.search);

    if(saveContact?.id !== null && saveContact?.id !== undefined){
        params.set('contact_id', saveContact.id);
    }

    if(docName && docName !== ''){
        params.set('filename', docName);
    }

    if (params.toString().length){
        documentViewUrl = documentViewUrl + '?' + params;
    }

    const dispatch = useDispatch();

    const handleShare = async () => {
        await onShare(sentTo);
        await onClose();
    }

    const handleShareClick = async () => {
        const contact = { contact_id: saveContact?.id, filename: docName }
        await dispatch(Actions.postDocuments( documentId, contact ));
        await setPage(1);
    };
    const handleGenerate = async () => {
        window.open(documentViewUrl, "_blank");
        await onClose();
        await dispatch(Actions.saveContactInMemory(null, null, false));
    };

    // const handleCreateContact = async () => {
    //     await dispatch(Actions.createContact({ full_name: saveContact.full_name } ));
    // }

    // useEffect(() => {
    //     if(saveContact?.showCreateNewContactButton){
    //         dispatch(Actions.createContact({ full_name: saveContact.full_name } ));
    //     }
    // }, [saveContact?.showCreateNewContactButton])

    useEffect(() => {
        if (wizardId){
            setWizardUrl(new URL(`${SERVER_URL}/wizard/${wizardId}`));
        }
    }, [wizardId])

    const handleClose = async () => {
        await onClose();
        await setPage(0);
        await setDocName(undefined);
        await dispatch(Actions.saveContactInMemory(null, null, false));
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            className={classes.root}
            scroll='paper'
            classes={{paper: classes.paper}}>
            <DialogTitle id="simple-dialog-title" classes={{root: classes.title}} >USE THIS DOCUMENT</DialogTitle>
            <DialogContent>
                {loadingPhase !== '' && <LinearProgress />}
                {page === 0 &&  <div>
                    <Typography className={classes.secondary}>Generate and/or share a LegalUp Document:</Typography>
                    <div className={classes.fieldContainer}>
                        <FormLabel className={classes.label}>Document name</FormLabel>
                        <BluishTextField variant='outlined' name='document_name' onChange={e => {setDocName(e.target.value); }}/>
                        <Typography className={classes.remark}>The document name is visible to everyone</Typography>
                    </div>

                    <div className={classes.fieldContainer}>
                        <FormLabel className={classes.label}>Client/project full name</FormLabel>
                        <div className='block_full_name'>
                            <DropdownAutocomplete  />
                            {/*{saveContact.showCreateNewContactButton && <StyledButton className={classes.button_new_contact} onClick={handleCreateContact}>Add</StyledButton>}*/}
                        </div>
                    </div>
                    <StyledButton className={classes.button} onClick={handleGenerate}>Generate</StyledButton>
                    <WhiteStyledButton className={classes.button} onClick={handleShareClick}>Generate and Share</WhiteStyledButton>
                    <center><Link className={classes.link} component="button" onClick={handleClose}>Cancel</Link></center>
                </div>}
                {page === 1 && <div>
                    <Typography className={classes.secondary}>You can copy this link to the wizard and share with your client.</Typography>
                    <div className={classes.fieldContainer}>
                        <BluishTextField variant='outlined' name='session_address' value={wizardUrl} onChange={e => {setDocName(e.target.value); }} />
                        <Link component="button" className={classes.preview} onClick={() => {
                            window.open(wizardUrl, "_blank");
                        }}>Preview Link in a new tab</Link>
                    </div>
                    {/*<div className={classes.fieldContainer}>*/}
                    {/*    <FormLabel className={classes.label}>Receiver's Name</FormLabel>*/}
                    {/*    <Typography className={classes.explanation}>To help trakcing you can state the name of the person or entity you intend to share the link with i.e. Jeff or ABC founders</Typography>*/}
                    {/*    <BluishTextField variant='outlined' name='sentTo' value={saveContact.full_name} onChange={e => {setSentTo(e.target.value); }} />*/}
                    {/*</div>*/}
                    <StyledButton className={classes.button} onClick={handleShare}>Done</StyledButton>
                    <center><Link className={classes.link} component="button" onClick={handleClose}>Cancel</Link></center>
                </div>}
            </DialogContent>
        </Dialog>
    );
}
