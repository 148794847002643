import * as Actions from '../actions';
import { createReducer } from '@reduxjs/toolkit';
import {
    DELETE_QUESTIONNAIRE,
    DOCUMENT_EXPANDED_ALL,
    DOCUMENT_EXPANDED_ALL_FALSE,
    DOCUMENT_EXPANDED_SELECTED, GET_CATEGORIES_FOR_CATEGORY,
    GET_DOCUMENTS_FOR_STATISTICS_COUNT,
    GET_DOCUMENTS_STATISTICS_DATE,
    SAVE_CONTACT_IN_MEMORY
} from "../actions";
import moment from "moment";

const initialState = {
    loadingPhase: '',
    organization: undefined,
    documents: [],
    documentsCategory: [],
    documentsStatistics: [],
    documentsStatisticsDate: {
        startDate: moment().subtract(0, 'months').startOf('month').format('YYYY-MM-DD'),
        endDate:  moment().subtract(0, 'months').endOf('month').format('YYYY-MM-DD'),
    },
    docsCount: 0,
    docsCategoryCount: 0,
    questionnaires: [],
    documentsPage: {
        expandAll: false,
        expanded : [],
    },
    saveContact:{
        id: null,
        full_name: null,
        showCreateNewContactButton: false,
        new: false,
    },
    contacts:[],
    countStatistics: {},
    categories: [],
};

const documentPageExpandAll = (state, action) => {
    state.documentsPage.expandAll = action.type == DOCUMENT_EXPANDED_ALL;
    if( action.type == DOCUMENT_EXPANDED_ALL && state.documentsPage.expanded.length > 0){
        state.documentsPage.expanded = [];
    }
};

const documentPageExpandOnlySelected = (state, action) => {
    if(action.data.length){
       state.documentsPage.expanded =  action.data;
    }
};

const getOrganization = (state, action) => {
    if (typeof action.success === 'boolean') {
        console.log('action', action);
        state.organization = action.organization;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const updateOrganization = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.organization = action.organization;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const getDocument = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const getDocuments = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.documents = action.documents.documents;
        state.docsCount = action.documents.count;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const getDocumentsForCategory = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.documentsCategory = action.documents.documents;
        state.docsCategoryCount = action.documents.count;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const clearDocumentsForCategory = (state, action) => {
        state.documentsCategory = [];
        state.docsCategoryCount = 0;
        state.loadingPhase = '';
};

const getDocumentsForStatistics = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.documentsStatistics = action.documents.documents;
        state.docsCount = action.documents.count;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}

const getDocumentsForStatisticsCount = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.countStatistics = action.countStatistics;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}


const getDocumentsStatisticsDate = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.documentsStatisticsDate.startDate = action.startDate;
        state.documentsStatisticsDate.endDate = action.endDate;
         state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}

const getContacts = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.contacts = action.contacts.contacts || [];
        state.contactsCount = action.contacts.count;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}

const showCreateNewContactButton = (state, action) => {
    state.saveContact.showCreateNewContactButton = action.value
}

const saveContactInMemory = (state, action) => {
    state.saveContact.id = action.id;
    state.saveContact.full_name = action.fullName;
    state.saveContact.new = action.isNew;
}

const createContact = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.contacts[state.contacts.length] = action.contact;
        state.contactsCount++;
        state.loadingPhase = '';
        state.saveContact = {
            id: action.contact.id,
            full_name: action.contact.full_name,
            showCreateNewContactButton: false,
        }
    } else {
        state.loadingPhase = 'fetching';
    }    
}

const updateContact = (state, action) => {
    if (typeof action.success === 'boolean') {
        const { contact } = action;
        const idx = state.contacts.findIndex(d=>d.id === contact.id);
        state.contacts[idx] = contact;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }    
}

const deleteContact = (state, action) => {
    if (typeof action.success === 'boolean') {
        const { contact } = action;
        const idx = state.contacts.findIndex(d => d.id === contact.id);
        state.contacts.splice(idx, 1);
        state.contactsCount--;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}

const getQuestionnaires = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.questionnaires = action.questionnaires;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const renameDocument = (state, action) => {
    if (typeof action.success === 'boolean') {
        const newDoc = action.renamed;
        const idx = state.documents.findIndex(d=>d.id === newDoc.id);
        state.documents[idx] = newDoc;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const deleteDocument = (state, action) => {
    if (typeof action.success === 'boolean') {
        const deleted = action.deleted;
        const idx = state.documents.findIndex(d => d.id === deleted);
        state.documents.splice(idx, 1);
        state.docsCount--;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}

const deleteQuestionnaire = (state, action) => {
    if (typeof action.success === 'boolean') {
        const deleted = action.deleted;
        const idx = state.questionnaires.findIndex(d => d.id === deleted);
        state.questionnaires.splice(idx, 1);
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}

const refreshUser = (state, action) => {
    if (typeof action.success === "boolean") {
        if (action.success) {
            state.user = action.user;
        } else {
            state.error = action.error;
            state.user = undefined;
        }
    }
};

const getCategories = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.categories = action.categories?.documents || [];
    }
}

const handlers = {};
handlers[Actions.GET_ORGANIZATION] = getOrganization;
handlers[Actions.UPDATE_ORGANIZATION] = updateOrganization;
handlers[Actions.GET_DOCUMENT] = getDocument;
handlers[Actions.GET_DOCUMENTS] = getDocuments;
handlers[Actions.GET_CATEGORIES_FOR_CATEGORY] = getDocumentsForCategory;
handlers[Actions.CLEAR_CATEGORIES_FOR_CATEGORY] = clearDocumentsForCategory;
handlers[Actions.GET_DOCUMENTS_FOR_STATISTICS] = getDocumentsForStatistics;
handlers[Actions.GET_DOCUMENTS_FOR_STATISTICS_COUNT] = getDocumentsForStatisticsCount;
handlers[Actions.GET_QUESTIONNAIRES] = getQuestionnaires;
handlers[Actions.RENAME_DOCUMENT] = renameDocument;
handlers[Actions.DELETE_DOCUMENT] = deleteDocument;
handlers[Actions.DELETE_QUESTIONNAIRE] = deleteQuestionnaire;
handlers[Actions.REFRESH_USER] = refreshUser;
handlers[Actions.GET_CONTACTS] = getContacts;
handlers[Actions.SAVE_SHOW_CREATE_NEW_CONTACT_BUTTON] = showCreateNewContactButton;
handlers[Actions.SAVE_CONTACT_IN_MEMORY] = saveContactInMemory;
handlers[Actions.CREATE_CONTACT] = createContact;
handlers[Actions.UPDATE_CONTACT] = updateContact;
handlers[Actions.DELETE_CONTACT] = deleteContact;
handlers[Actions.DOCUMENT_EXPANDED_ALL] = documentPageExpandAll;
handlers[Actions.DOCUMENT_EXPANDED_ALL_FALSE] = documentPageExpandAll;
handlers[Actions.DOCUMENT_EXPANDED_SELECTED] = documentPageExpandOnlySelected;
handlers[Actions.GET_DOCUMENTS_STATISTICS_DATE] = getDocumentsStatisticsDate;
handlers[Actions.GET_CATEGORIES] = getCategories;


export default createReducer(initialState, handlers);