import api from '../api';
import { io } from 'socket.io-client';
import { BASE_URL } from '../api';
import moment from "moment";

export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_FOR_STATISTICS = 'GET_DOCUMENTS_FOR_STATISTICS';
export const GET_DOCUMENTS_FOR_STATISTICS_COUNT = 'GET_DOCUMENTS_FOR_STATISTICS_COUNT';
export const GET_DOCUMENTS_STATISTICS_DATE = 'GET_DOCUMENTS_STATISTICS_DATE';
export const GET_QUESTIONNAIRES = 'GET_QUESTIONNAIRES';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const CREATE_QUESTIONNAIRE = 'CREATE_QUESTIONNAIRE';
export const POST_ANSWERS = 'POST_ANSWERS';
export const POST_DOCUMENTS = 'POST_DOCUMENTS';
export const GET_CONTACTS_WIZARD = 'GET_CONTACTS_WIZARD';
export const SAVE_ANSWER = 'SAVE_ANSWER';
export const LOAD_PROGRESS = 'LOAD_PROGRESS';
export const RENAME_DOCUMENT = 'RENAME_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const REFRESH_USER = 'REFRESH_USER';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SIGNATURE = 'DOWNLOAD_FILE_SIGNATURE';
export const RESET_QUESTIONNAIRE = 'RESET_QUESTIONNAIRE';
export const DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE';
export const SIGN_FILE = 'SIGN_FILE';
export const GET_CONTACTS = 'GET_CONTACTS';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const SAVE_SHOW_CREATE_NEW_CONTACT_BUTTON = 'SAVE_SHOW_CREATE_NEW_CONTACT_BUTTON';
export const SAVE_CONTACT_IN_MEMORY = 'SAVE_CONTACT_IN_MEMORY';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

export const DOCUMENT_EXPANDED_ALL = 'DOCUMENT_EXPANDED_ALL';
export const DOCUMENT_EXPANDED_ALL_FALSE = 'DOCUMENT_EXPANDED_ALL_FALSE';
export const DOCUMENT_EXPANDED_SELECTED = 'DOCUMENT_EXPANDED_SELECTED';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_FOR_CATEGORY = 'GET_CATEGORIES_FOR_CATEGORY';
export const CLEAR_CATEGORIES_FOR_CATEGORY = 'CLEAR_CATEGORIES_FOR_CATEGORY';


export const initGlobalWS = (dispatch) => {
    const socket = io(BASE_URL, {
        reconnectionDelayMax: 10000,
        secure: true
    });

    socket.on('connect', () => {
        console.log('socket.io connected - ' + socket.id);

        socket.on('file_generated', ({document_id, questionnaire_id, file_id, has_signatures}) => {
            dispatch({ type: DOWNLOAD_FILE, success: true, file: {document_id, questionnaire_id, id: file_id, has_signatures} });
        });
    });
}


export function getOrganization(orgId) {
    return async (dispatch) => {
        dispatch({ type: GET_ORGANIZATION });
        try {
            console.log('sending', orgId);
            const organization = await api.organizations.get(orgId);
            dispatch({ type: GET_ORGANIZATION, success: true, organization });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ORGANIZATION, success: false, error });
        }
    };
}

export function getDocumentAnonymously(docId) {
    return async(dispatch) => {
        dispatch({ type: GET_DOCUMENT });
        try {
            const doc = await api.documents.get(docId);
            console.log(doc);
            dispatch({ type: GET_DOCUMENT, success: true });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DOCUMENT, success: false, error });
        }
    };
}

export function getContacts(pageIndex = 0, pageSize = 20, text = '') {
    return async(dispatch) => {
        dispatch({ type: GET_CONTACTS });
        try {
            const doc = await api.contacts.getAll(pageIndex, pageSize, '', text);
            dispatch({ type: GET_CONTACTS, success: true, contacts: doc });
        } catch (error) {
            console.error({error});
            dispatch({ type: GET_CONTACTS, success: false, error });
            throw error;
        }
    };
}

export function showCreateNewContactButton(value) {
    return async (dispatch, getState) => {
        dispatch({ type: SAVE_SHOW_CREATE_NEW_CONTACT_BUTTON, value });
    }
}

export function saveContactInMemory(id, fullName, isNew = null) {
    return async (dispatch, getState) => {
        dispatch({ type: SAVE_CONTACT_IN_MEMORY, id, fullName, isNew });
    }
}

export function createContact(contact) {
    return async (dispatch) => {
        dispatch({ type: CREATE_CONTACT });
        try {
            const c = await api.contacts.create(contact);
            dispatch({ type: CREATE_CONTACT, success: true, contact: c });
        } catch (error) {
            console.error({ error });
            dispatch({ type: CREATE_CONTACT, success: false, error });
        }
    }
}

export function updateContact(contact) {
    return async (dispatch) => {
        dispatch({ type: UPDATE_CONTACT });
        try {
            const c = await api.contacts.update(contact);
            dispatch({ type: UPDATE_CONTACT, success: true, contact: c });
        } catch (error) {
            console.error({ error });
            dispatch({ type: UPDATE_CONTACT, success: false, error });
        }
    }
}

export function deleteContact(contact) {
    return async (dispatch) => {
        dispatch({ type: DELETE_CONTACT });
        try {
            await api.contacts.delete(contact.id);
            dispatch({ type: DELETE_CONTACT, success: true, contact });
        } catch (error) {
            console.error({ error });
            dispatch({ type: DELETE_CONTACT, success: false, error });
        }
    }
}

export function getDocuments(pageIndex = 0, pageSize = 20) {
    return async(dispatch) => {
        dispatch({ type: GET_DOCUMENTS });
        try {
            const doc = await api.documents.getAll(pageIndex, pageSize);
            dispatch({ type: GET_DOCUMENTS, success: true, documents: doc });
        } catch (error) {
            console.error({error});
            dispatch({ type: GET_DOCUMENTS, success: false, error });
            throw error;
        }
    };
}

export function getDocumentsForCategory(pageIndex = 0, pageSize = 50, categoryId) {
    return async(dispatch) => {
        dispatch({ type: GET_CATEGORIES_FOR_CATEGORY });
        try {
            const doc = await api.documents.getAllForCategory(pageIndex, pageSize, categoryId);
            dispatch({ type: GET_CATEGORIES_FOR_CATEGORY, success: true, documents: doc });
        } catch (error) {
            console.error({error});
            dispatch({ type: GET_CATEGORIES_FOR_CATEGORY, success: false, error });
            throw error;
        }
    };
}

export function clearDocumentsForCategory() {
    return async(dispatch) => {
        dispatch({ type: CLEAR_CATEGORIES_FOR_CATEGORY });
    };
}

//documents-statistic
export function getDocumentsForStatistics(pageIndex = 0, pageSize = 20, from , to) {
    return async(dispatch) => {
        dispatch({ type: GET_DOCUMENTS_FOR_STATISTICS });
        try {
            const doc = await api.documents.getAllDocumentsForStatistics(pageIndex, pageSize, from , to);
            dispatch({ type: GET_DOCUMENTS_FOR_STATISTICS, success: true, documents: doc });
        } catch (error) {
            console.error({error});
            dispatch({ type: GET_DOCUMENTS_FOR_STATISTICS, success: false, error });
            throw error;
        }
    };
}

export function getDocumentsForStatisticsCount(id, from , to) {
    return async(dispatch) => {
        dispatch({ type: GET_DOCUMENTS_FOR_STATISTICS_COUNT });
        try {
            const count = await api.documents.getAllDocumentsForStatisticsCount(id, from , to);
            dispatch({ type: GET_DOCUMENTS_FOR_STATISTICS_COUNT, success: true, countStatistics: count });
        } catch (error) {
            console.error({error});
            dispatch({ type: GET_DOCUMENTS_FOR_STATISTICS_COUNT, success: false, error });
            throw error;
        }
    };
}

export function openQuestionnaire(id) {
    return async(dispatch) => {
        dispatch({ type: GET_QUESTIONNAIRE });
        try {
            const questionnaire = await api.questionnaires.get(id);
            const document = await api.documents.get(questionnaire.document_id);
            console.log('FROM SERVER');
            console.log({questionnaire, document});
            dispatch({ type: GET_QUESTIONNAIRE, success: true, questionnaire, document });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_QUESTIONNAIRE, success: false, error });
        }
    };
}

export function getQuestionnaires(docId='', text='' ) {
    return async(dispatch) => {
        dispatch({ type: GET_QUESTIONNAIRES });
        try {
            const doc = await api.questionnaires.getAll(docId, text);
            console.log(doc);
            dispatch({ type: GET_QUESTIONNAIRES, success: true, questionnaires: doc.questionnaires });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_QUESTIONNAIRES, success: false, error });
        }
    };
}

export function getDocumentsStatisticsDate(startDate, endDate) {
    return (dispatch) => {
        dispatch({ success: true, type: GET_DOCUMENTS_STATISTICS_DATE, startDate, endDate });
    };
}

export function documentsExpand(expanded= false) {
    return (dispatch) => {
        console.log('expanded', expanded)
       if(expanded){
           dispatch({ type: DOCUMENT_EXPANDED_ALL });
       } else {
           dispatch({ type: DOCUMENT_EXPANDED_ALL_FALSE });
       }
    };
}

export function stateDocumentsExpand(data) {
    return (dispatch) => {
        if(data.length){
            dispatch({ type: DOCUMENT_EXPANDED_SELECTED, data });
        } else {
            dispatch({ type: DOCUMENT_EXPANDED_ALL_FALSE });
        }
    };
}

export function createQuestionnaire(questionnaire) {
    return async(dispatch) => {
        dispatch({ type: CREATE_QUESTIONNAIRE });
        try {
            await api.questionnaires.create(questionnaire);
            const document = await api.documents.get(questionnaire.document_id);
            dispatch({ type: CREATE_QUESTIONNAIRE, success: true, questionnaire, document });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_QUESTIONNAIRE, success: false, error });
        }
    };
}

export function postAnswers(goBack) {
    return async (dispatch, getState) => {
        dispatch({ type: POST_ANSWERS });
        const state = getState().questionnaire;
        try {
            for (let i = 0; i < state.dirtyIds.length; ++i) {
                const answer = state[state.dirtyIds[i]];
                await api.questionnaires.post(state.id, answer);
            }
            dispatch({ type: POST_ANSWERS, success: true, back: !!goBack });
        } catch (error) {
            console.error(error);
            dispatch({ type: POST_ANSWERS, success: false, error });
        }
    };
}

export function postAnswersMap(index) {
    return async (dispatch, getState) => {
        dispatch({ type: POST_ANSWERS, success: true, index: index });
    };
}

export function saveAnswer(answer) {
    // answer is in the form of { question_id: 'x', value: 'y', other_value: 'z', children: [] }
    return async (dispatch, getState) => {
        dispatch({ type: SAVE_ANSWER, answer });
    }
}

export function downloadFile() {
    return async (dispatch, getState) => {
        dispatch({ type: DOWNLOAD_FILE });
        const state = getState().questionnaire;
        const questions = state.questions;
        const body = [];
        for (let i = 0; i < questions.length; ++i) {
            const qid = questions[i].id;
            const value = state[qid];
            if (value) {
                if (Array.isArray(value)) {
                    for (let j = 0; j < value.length; ++j) {
                        body.push(value[j]);
                    }
                } else {
                    body.push(value);
                }
            }
        }
        api.files.create(state.id, state.document.id, body, state.filename).catch( (err) => {
            console.log(err)
        });

    }
}

export function downloadFileSignature(base64) {
    return async (dispatch, getState) => {
        dispatch({ type: DOWNLOAD_FILE_SIGNATURE });
        const state = getState().questionnaire;
        const questions = state.questions;
        const body = [];
        for (let i = 0; i < questions.length; ++i) {
            const qid = questions[i].id;
            const value = state[qid];
            if (value) {
                if (Array.isArray(value)) {
                    for (let j = 0; j < value.length; ++j) {
                        body.push(value[j]);
                    }
                } else {
                    body.push(value);
                }
            }
        }
        api.files.uploadSignature(state.id, state.document.id, body, state.filename, base64).catch( (err) => {
            console.log(err)
        });

    }
}

export function signFile() {
    return async (dispatch, getState) => {
        dispatch({ type: SIGN_FILE });
        const state = getState().questionnaire;
        try {
            await api.files.sign(state.fileId);
            dispatch({ type: SIGN_FILE, success: true });
        } catch (error) {
            dispatch({ type: SIGN_FILE, success: false, error });
        }
    }
}

export function signFileById(fileId) {
    return async (dispatch) => {
        dispatch({ type: SIGN_FILE });
        try {
            await api.files.sign(fileId);
            dispatch({ type: SIGN_FILE, success: true });
        } catch (error) {
            dispatch({ type: SIGN_FILE, success: false, error });
        }
    }
}

export function renameDocument(docId, name) {
    return async(dispatch) => {
        dispatch({ type: RENAME_DOCUMENT });
        try {
            const doc = await api.documents.rename(docId, name);
            dispatch({ type: RENAME_DOCUMENT, success: true, renamed: doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: RENAME_DOCUMENT, success: false, error });
        }
    };
}

export function deleteDocument(docId) {
    return async(dispatch) => {
        dispatch({ type: DELETE_DOCUMENT });
        try {
            await api.documents.delete(docId);
            dispatch({ type: DELETE_DOCUMENT, success: true, deleted: docId });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_DOCUMENT, success: false, error });
        }
    };
}

export function deleteQuestionnaire(questionnaireId) {
    return async(dispatch) => {
        dispatch({ type: DELETE_QUESTIONNAIRE });
        try {
            await api.questionnaires.delete(questionnaireId);
            dispatch({ type: DELETE_QUESTIONNAIRE, success: true, deleted: questionnaireId });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_QUESTIONNAIRE, success: false, error });
        }
    };
}

export function refreshUser(id) {
    return async (dispatch) => {
        dispatch({ type: REFRESH_USER });
        try {
            const user = await api.users.get(id);
            dispatch({ type: REFRESH_USER, user, success: true });
        } catch (error) {
            dispatch({ type: REFRESH_USER, error, success: false });
            console.error(error);
            localStorage.removeItem("access_token");
            localStorage.removeItem("current_user");
        }
    };
}

export function resetQuestionnaire() {
    return { type: RESET_QUESTIONNAIRE };
}

export function updateOrganization(updateDoc) {
    return async (dispatch) => {
        dispatch({ type: UPDATE_ORGANIZATION });
        try {
            const organization = await api.organizations.update(updateDoc);
            dispatch({ type: UPDATE_ORGANIZATION, organization, success: true });
        } catch (error) {
            dispatch({ type: UPDATE_ORGANIZATION, error, success: false });
            console.error(error);
        }
    };
}

export function postDocuments(docId, contact = {}) {
    return async (dispatch) => {
        dispatch({ type: POST_DOCUMENTS });
        try {
            const doc = await api.documents.post(docId, contact);
            dispatch({ type: POST_DOCUMENTS, success: true, doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: POST_DOCUMENTS, success: false, error });
        }
    };
}

export function getContactsWizard(wizardId) {
    return async (dispatch) => {
        dispatch({ type: GET_CONTACTS_WIZARD });
        try {
            const contacts = await api.documents.getContacts(wizardId);
            dispatch({ type: GET_CONTACTS_WIZARD, success: true, contacts });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CONTACTS_WIZARD, success: false, error });
        }
    };
}

export function getCategories() {
    return async(dispatch) => {
        dispatch({ type: GET_CATEGORIES });
        try {
            const doc = await api.organizations.getCategory();
            dispatch({ type: GET_CATEGORIES, success: true, categories: doc });
        } catch (error) {
            console.error({error});
            dispatch({ type: GET_CATEGORIES, success: false, error });
            throw error;
        }
    };
}