import React, {useRef, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions
} from '@material-ui/core';
import Done from '@material-ui/icons/DoneRounded';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SignatureCanvas from 'react-signature-canvas';
import api from "../api";
import * as Actions from "../redux/actions";
import {downloadFileSignature} from "../redux/actions";
import {useDispatch} from "react-redux";

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: 'white',
        backgroundColor: '#1274E4',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#1060BB'
      },
    },
}))(Button);
const WhiteStyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: '#1274E4',
        textTransform: 'none',
        backgroundColor: 'white',
        border: '1px solid #1274E4',
        '&:hover': {
            backgroundColor: 'rgba(240, 240, 240, 0.8)'
      },
    },
}))(Button);

const BluishTextArea = withStyles({
    root: {
        margin: '14px 10px',
        backgroundColor: '#F9F9F9',
        boxShadow: '0px',
        width: '100%',
        '& input, textarea, span': {            
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            textAlign: 'left',
            font: 'normal normal normal 18px/28px Assistant',
            letterSpacing: '0px',
            color: '#35373D',
            borderRadius: '5px',
        },
        '& input::placeholder': {
            fontFamily: 'Assistant',
            fontSize: '12px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#565656'
        },
        '& label.Mui-focused': {
            color: '#4789d2',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#4789d2',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(208, 208, 208, 1)',
        },
        '& .MuiOutlinedInput-input': {
            padding: '9px 8px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(238, 238, 238, 1)',
                borderRadius: '4px',
            },
            '&:hover fieldset': {
                borderColor: '#808080',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#4789d2',
            },
        },
    },
})(Typography)

const useStyles = makeStyles(theme => ({
    root: {
    },
    paper: {
        margin: '0px',
        width: '520px',
        height: '660px',
        overflowY: 'auto'
    },
    title: {
        marginTop: '12px',
        fontFamily: 'Assistant',        
        color: 'white',
        backgroundColor: 'white', //theme.palette.primary[50],
        height: '28px',
        '& > .MuiTypography-root': {
            textAlign: 'left',
            font: 'normal normal bold 22px/28px Assistant',
            letterSpacing: '0px',
            color: '#35373D',
            opacity: 1
        }
    },
    title2: {
        marginTop: '12px',
        marginBottom: '15px',
        fontFamily: 'Assistant',
        color: 'white',
        backgroundColor: 'white', //theme.palette.primary[50],
        height: '28px',
        '& > .MuiTypography-root': {
            textAlign: 'center',
            font: 'normal normal bold 38px/48px Assistant',
            letterSpacing: '0px',
            color: '#35373D',
            opacity: 1
        }
    },
    align: {
        textAlign: 'center',
    },
    grats: {
        textAlign: 'left',
        font: 'normal normal bold 18px/28px Assistant',
        letterSpacing: '0px',
        color: '#35373D',
        margin: '4px 0px',
    },    
    secondary: {
        textAlign: 'left',
        font: 'normal normal normal 15px/24px Assistant',
        letterSpacing: '0px',
        color: '#35373D',
        margin: '4px 0px',
        marginBottom: '20px'
    },
    secondary2: {
        textAlign: 'center',
        font: 'normal normal normal 20px/30px Assistant',
        letterSpacing: '0px',
        color: '#35373D',
        margin: '24px 0px',
        marginBottom: '20px'
    },
    remark: {
        fontSize: '10px',
        fontFamily: 'Assistant',
        color: 'rgba(60, 60, 60, 0.5)'
    },
    // button: {
    //     width: '100%',
    //     margin: '13px 0px',
    //     height: '38px',
    //     borderRadius: '25px',
    // },
    label: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0px',
        color: '#35373D'
    },
    link: {
        fontSize: '13px',
        fontWeight: 400,
        color: '#57606B'
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin : '24px 0px'
    },
    preview: {
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Assistant',
        letterSpacing: '0px',
        color: '#1274E4'
    },
    explanation: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '11px',
        letterSpacing: '0px',
        color: '#57606B',
        margin: '6px 0px'
    },
    doneIcon: {
        alignSelf: 'center',
        color: '#1274E4',
        width: '26px',
        height: '26px',
        backgroundColor: 'transparent'
    },
    inputContainer: {
        display: 'flex',
        background: '#F9F9F9',
        boxShadow: '0 0 black',
        flexDirection: 'row',
        border: '1px solid #EBEBEB'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        margin: '18px 18px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '42px'
    },
    button: {
        width: ' 172px',
        height: '42px',
        borderRadius: '21px',
        margin: '0px 4px'
    },
    thumbUpIcon: {
        borderRadius:'50%',
        background:'#37c53a',
        width: '120px',
        display: 'inline-flex',
        height:' 120px',
        alignItems: 'center',
        justifyContent: 'center',
    },

}));

export default function DocumentReadyDialog(props) {
    const classes = useStyles();
    const {open, questionnaire, onClose, onDownload, onSign , on_finish_target_origin = ''} = props;
    const name = questionnaire.filename || '';
    const sigCanvas = useRef(null);
    const [openSignature, setOpenSignature] = useState(false);

    const dispatch = useDispatch();
    const clearSignature = () => {
        sigCanvas.current.clear();
    };

    const saveSignature = () => {
        const dataURL = sigCanvas.current.toDataURL();
        const base64String = dataURL.split(",")[1];
        dispatch(Actions.downloadFileSignature(base64String));
        handleCloseSignature();
    };

    const handleClickOpen = () => {
        setOpenSignature(true);
    };

    const handleCloseSignature = () => {
        setOpenSignature(false);
    };

    const {
        fileDownloadLink,
    } = questionnaire;

    if ( on_finish_target_origin && open ) {
        console.log('-----postMessage------',
            {
                id: questionnaire.id,
                fileDownloadLink: 'the secret'
            }
        )

        window.parent.postMessage(
            {
                id: questionnaire.id,
                fileDownloadLink: questionnaire.fileDownloadLink,
            },
            on_finish_target_origin);
    }

    const handleClose = async () => {
        await onClose();
    };
    const [currUser] = React.useState((localStorage.getItem("current_user") && JSON.parse(localStorage.getItem("current_user")))|| undefined);
    const signText = currUser ? "Sign with docusign" : 'Sign with docusign';

    return (
        <>
            {
                on_finish_target_origin && open ?
                    <Dialog
                        onClose={handleClose}
                        aria-labelledby="simple-dialog-title"
                        open={open}
                        className={classes.root}
                        scroll='paper'
                        classes={{paper: classes.paper}}>
                        <DialogTitle id="simple-dialog-title" classes={{root: classes.title2}} >Thank you!</DialogTitle>
                        <DialogContent className={classes.align}>

                            <div className={classes.thumbUpIcon}>
                                <ThumbUpIcon htmlColor='#fff' fontSize="large"></ThumbUpIcon>
                            </div>


                            <p className={classes.secondary2}>{`Your answers were submitted successfully`}</p>
                            <div className={classes.buttons}>
                                <WhiteStyledButton className={classes.button} onClick={onClose}>Back</WhiteStyledButton>
                                {!on_finish_target_origin && <StyledButton className={classes.button} onClick={onDownload}>Download</StyledButton>}
                                {!on_finish_target_origin && questionnaire && questionnaire.has_signatures &&
                                <StyledButton className={classes.button} onClick={onSign}>{signText}</StyledButton>}
                            </div>
                        </DialogContent>
                    </Dialog>
            :
                    <Dialog
                        onClose={handleClose}
                        aria-labelledby="simple-dialog-title"
                        open={open}
                        className={classes.root}
                        scroll='paper'
                        classes={{paper: classes.paper}}>
                        <DialogTitle id="simple-dialog-title" classes={{root: classes.title}} >Your document is ready</DialogTitle>
                        <DialogContent>
                            <h3 className={classes.grats}>Congratulations</h3>
                            <p className={classes.secondary}>{`The system has generated your ${name} based on the answers entered`}</p>
                            <Paper className={classes.inputContainer}>
                                <BluishTextArea readOnly variant='body1' name='email_to' >{name}</BluishTextArea>
                                <IconButton  className={classes.doneIcon}>
                                    <Done />
                                </IconButton>
                            </Paper>
                            <div className={classes.buttons}>
                                <WhiteStyledButton className={classes.button} onClick={onClose}>Back</WhiteStyledButton>
                                {!on_finish_target_origin && <StyledButton className={classes.button} onClick={onDownload}>Download</StyledButton>}
                                { (!on_finish_target_origin && questionnaire && questionnaire.has_signatures && questionnaire.signature_type !== 'draw')
                                    ? <StyledButton className={classes.button} onClick={onSign}>{signText}</StyledButton>
                                    : (!on_finish_target_origin && questionnaire && questionnaire.has_signatures && questionnaire.signature_type === 'draw')
                                        ?  <StyledButton className={classes.button} onClick={handleClickOpen}>Draw Signature</StyledButton>
                                        : null
                                }

                            </div>
                            { questionnaire && questionnaire.final_format === 'pdf' && questionnaire.signature_type === 'draw' &&
                                <div>
                                    <iframe src={`${fileDownloadLink}?open=true`}
                                            style={{ border: 'none' }}
                                            width='100%' height='300px'
                                    />
                                </div>
                            }

                            <Dialog open={openSignature} onClose={handleCloseSignature} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Signature</DialogTitle>
                                <DialogContent style={{ border: "1px solid #EBEBEB", padding: '0' }}>
                                    <SignatureCanvas
                                        ref={sigCanvas}
                                        canvasProps={{ width: 580, height: 60 }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <StyledButton variant="contained" color="secondary" onClick={clearSignature}>
                                        Clear
                                    </StyledButton>
                                    <WhiteStyledButton onClick={handleCloseSignature} color="primary">
                                        Close
                                    </WhiteStyledButton>
                                    <WhiteStyledButton onClick={saveSignature} color="primary">
                                        Save
                                    </WhiteStyledButton>
                                </DialogActions>
                            </Dialog>


                        </DialogContent>
                    </Dialog>
            }
        </>

  );
}

