import React, { useState,  useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import  { useParams, useHistory  } from 'react-router-dom';
import * as Actions from "../redux/actions";
import { LinearProgress } from  '@material-ui/core';
import Header from "./Header";

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import {isMobile} from "react-device-detect";
import Logo from './Logo';
import LogoLoad from './LogoLoading';

// make the drawer width bigger or smaller, depends on the max text field length
const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    mainContainer:{
        margin: 'auto',
        width: isMobile ? '320px' : '600px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch'
    },
    documentsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '320px' : '600px',
        height: '30vh',
        // flexGrow: 1,
        backgroundColor: 'white',
        margin: 'auto',
        marginTop: '160px'
    },
}));

export default function MainDocumentView() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const loadingPhase = useSelector(state => state.questionnaire.loadingPhase);
    const wizardId = useSelector(state => state.questionnaire.wizardId);
    const error = useSelector(state => state.questionnaire.error);
    const { documentId } = useParams();
    const contact_id = searchParams.get('contact_id');
    const filename = searchParams.get('filename');
    const forAddin = searchParams.get('forAddin');
    //const client_name = searchParams.get('client_name');

    const contact = { contact_id, filename }
    useEffect(() => {
        dispatch(Actions.postDocuments( documentId, contact ));
    }, [dispatch]);

    if(wizardId){
        let issetForAddin = '';
        if(forAddin){
            issetForAddin = `?forAddin=${forAddin}`;
        }
        history.push(`/wizard/${wizardId}${issetForAddin}`);
    }

    return (
        <div className={classes.mainContainer}>
            <Header />
            <div className={classes.documentsContainer}>
                {loadingPhase === 'fetching' && <div className="load">
                    <LogoLoad className="logo" />
                    <div className="loader"></div>
                </div> }
                { error }
            </div>
        </div>
    );
}