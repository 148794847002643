import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import {LinearProgress, IconButton, Button, DialogContent} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditTwoTone, DeleteTwoTone } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as Actions from '../redux/actions';
// import { SERVER_URL } from '../api';
import CreateContactDialog from './CreateContactDialog';
import AlertDialog from './AlertDialog';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        // maxHeight: '400px'
        maxHeight: '80vh'
    },
    flexGrow: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    rightContainer: {
        width :'20px',
        flexGrow: 0,
        backgroundColor: 'white'
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    actionLink: {
        margin: '0px 12px',
        cursor: 'pointer'
    },
    actionIcon: {
        width: '12px',
        height: '12px',
        marginLeft: '12px'
    },
    actionButton: {
        width: '12px',
        height: '12px',
    },
    fullRow: {
        width: '100%'
    }
}));

export default function ContactList() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const contacts = useSelector(state => state.main.contacts);
    const contactsCount = useSelector(state => state.main.contactsCount);
    const loadingPhase = useSelector(state => state.main.loadingPhase);

    const [createDialogMode, setCreateDialogMode] = useState('');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [currentContact, setCurrentContact] = useState(undefined);
    const [page, setPage] = useState(1);

    const columns = [
        {
            name: 'actions',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div className={classes.actionsContainer}>
                        <IconButton aria-label="update" color="primary" className={classes.actionIcon} onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            setCurrentContact(contacts[tableMeta.rowIndex]);
                            setCreateDialogMode('update');
                        }}>
                            <EditTwoTone/>
                            {/*<img src={`${SERVER_URL}/static/media/icons_edit.svg`} className={classes.actionButton} alt={''} />*/}
                        </IconButton>
                        <IconButton aria-label="delete" color="primary" className={classes.actionIcon} onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            setCurrentContact(contacts[tableMeta.rowIndex]);
                            setShowDeleteDialog(true);
                        }}>
                            <DeleteTwoTone/>
                            {/*<img src={`${SERVER_URL}/static/media/icons_delete.svg`} className={classes.actionButton} alt={''} />*/}
                        </IconButton>
                    </div>
                }
            }
        }, {
            name: "full_name",
            label: "Full Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "first_name",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "last_name",
            label: "Last Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "client_type",
            label: "Client Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "mobile_phone",
            label: "Mobile Phone",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "work_phone",
            label: "Work Phone",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "home_phone",
            label: "Home Phone",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "fax_phone",
            label: "Fax Phone",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "address",
            label: "Street Address",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "country",
            label: "Country",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "state",
            label: "State",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "zip_code",
            label: "Zip code",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "full_address",
            label: "Full Address",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "responsible_partner_name",
            label: "Responsible Partner Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "leading_partner_name",
            label: "Leading Partner Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "company_name",
            label: "Company Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "foreign_company_name",
            label: "Foreign Company Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "title",
            label: "Title",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "id_number",
            label: "Id Number",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "city",
            label: "City",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "id",
            label: "Contact Id",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "created_at",
            label: "Created At",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment.utc(value*1000).format('llll');
                },
            }
        }, {
            name: "updated_at",
            label: "Updated At",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment.utc(value*1000).format('llll');
                },
            }
        }
    ];

    const loadContacts = async () => {
        await dispatch(Actions.getContacts(page - 1, 20));
    };

    useEffect(() => {      
        loadContacts();
    }, [dispatch, page]);
    
    const handleHide = async () => {
        setShowDeleteDialog(false);
        setCurrentContact(undefined);
    }

    const handleCreateContact = async () => {
        setCreateDialogMode('create');
    }

    const infoName = currentContact && currentContact?.full_name !=='' ? currentContact?.full_name : currentContact?.first_name + ' ' + currentContact?.last_name;

    return(
        <div className='contacts_page'>
            <div className={classes.root}>
            {loadingPhase === 'fetching' && <LinearProgress style={{width: '100%'}} color="primary" />}
            <AlertDialog
                open={showDeleteDialog}
                onCancel={handleHide}
                onOk={()=> {
                    handleHide();
                    dispatch(Actions.deleteContact(currentContact));
                }}
                title={'Are you sure?'}
                message={`Are you sure you want to delete ${infoName}?`}
            />
            <CreateContactDialog
                onCreate={async (contact) => {
                    dispatch(Actions.createContact(contact));
                }}
                onUpdate={async (contact) => {
                    dispatch(Actions.updateContact({...currentContact, ...contact}));
                }}
                onClose={async () => {
                    await setCreateDialogMode('');
                }}
                mode={createDialogMode}
                currentContact={currentContact || {}}
            />
            <MUIDataTable
                // title={"My LegalUp contacts"}
                data={contacts}
                columns={columns}
                className={classes.flexGrow}
                options={{
                    page: page - 1,
                    filter: false,
                    download: false,
                    print: false,
                    select: false,
                    search: false,
                    pagination: true,
                    rowsPerPageOptions: [20],
                    rowsPerPage: 20,
                    serverSide: true,
                    count: contactsCount,
                    customToolbar: null,
                    selectableRows: false,
                    sort: true,
                    viewColumns: false,
                    expandableRows: false,
                    expandableRowsOnClick: true,
                    elevation: 0,
                    onTableChange: async (action, tableState) => {
                        if (tableState.page + 1 !== page) {
                            setPage(tableState.page + 1);
                        }
                    }
                }}
            />
            <Button className='button-m-top' onClick={handleCreateContact}>Create Contact</Button>
            <div className={classes.rightContainer}></div>
        </div>
    </div>
    );
}
