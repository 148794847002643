import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions}  from '@material-ui/core';

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: 'white',
        backgroundColor: '#DC143C',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#CF092F'
      },
    },
}))(Button);
const WhiteStyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: '#DC143C',
        textTransform: 'none',
        backgroundColor: 'white',
        border: '1px solid #DC143C',
        '&:hover': {
            backgroundColor: 'rgba(240, 240, 240, 0.8)'
      },
    },
}))(Button);

const useStyles = makeStyles(theme => ({
    root: {
    },
    paper: {
        margin: '0px',
        width: '400px',
        height: '260px',
        overflowY: 'auto',
    },
    title: {
        fontFamily: 'Assistant',        
        color: 'white',
        backgroundColor: '#DC143C',
        height: '28px',
        '& > .MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 700,
            color: 'white'
        }
    },
    secondary: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        letterSpacing: '0px',
        margin: '10px 0px',
        color: '#35373D'
    },
    remark: {
        fontSize: '10px',
        fontFamily: 'Assistant',
        color: 'rgba(60, 60, 60, 0.5)'
    },
    button: {
        width: '100%',
        margin: '13px 0px',
        height: '38px',
        borderRadius: '25px',
    },
    label: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0px',
        color: '#35373D'
    },
    link: {
        fontSize: '13px',
        fontWeight: 400,
        color: '#57606B'
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin : '24px 0px'
    },
    preview: {
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Assistant',
        letterSpacing: '0px',
        color: '#1274E4'
    },
    explanation: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '11px',
        letterSpacing: '0px',
        color: '#57606B',
        margin: '6px 0px'
    },
}));

export default function CreateContactDialog(props) {
    const classes = useStyles();
    const { mode, onClose, onCreate, onUpdate, currentContact } = props;

    const [contact, setContact] = useState(props.contact || {});

    const action = mode === 'update' ? 'Update' : 'Create';
    const title = action + ' Contact';
    
    const handleClose = async () => {
        setContact({});
        await onClose();
    };

    const handleSubmit = async () => {
        if (mode === 'update') {
            await onUpdate(contact);
            setContact({});
            await onClose();
        } else {
            await onCreate(contact);
            setContact({});
            await onClose();
        }
    }

    const contactField = (label, fieldName) => {
        const onChange = (event) => {
            setContact(Object.assign({}, contact, {[fieldName]: event.target.value }));
        }
        return <TextField
            id={'contact_'+fieldName}
            label={label}
            defaultValue={ currentContact[fieldName] || ''}
            onChange={onChange}
        />
    }

    return (
        <Dialog 
            onClose={handleClose} 
            aria-labelledby="simple-dialog-title" 
            open={!!mode}
            className={classes.root} 
            scroll='paper'
            classes={{paper: classes.paper}}>
            <DialogTitle id="simple-dialog-title" classes={{root: classes.title}} >{title}</DialogTitle>            
            <DialogContent>
                {contactField('Client Type', 'client_type')}
                {contactField('Full Name', 'full_name')}
                {contactField('First Name', 'first_name')}
                {contactField('Last Name', 'last_name')}
                {contactField('Company Name', 'company_name')}
                {contactField('Foreign Company Name', 'foreign_company_name')}
                {contactField('Title', 'title')}
                {contactField('ID Number', 'id_number')}
                {contactField('Email', 'email')}
                {contactField('Mobile Phone', 'mobile_phone')}
                {contactField('Work Phone', 'work_phone')}
                {contactField('Home Phone', 'home_phone')}
                {contactField('Fax', 'fax_phone')}
                {contactField('Street Address', 'address')}
                {contactField('City', 'city')}
                {contactField('Country', 'country')}
                {contactField('State', 'state')}
                {contactField('Zip code', 'zip_code')}
                {contactField('Full Address', 'full_address')}
                {contactField('Responsible Partner Name', 'responsible_partner_name')}
                {contactField('Leading Partner Name', 'leading_partner_name')}
            </DialogContent>
            <DialogActions>
                <StyledButton className={classes.button} onClick={handleSubmit}>{action}</StyledButton>
                <WhiteStyledButton className={classes.button} onClick={handleClose}>Cancel</WhiteStyledButton>
            </DialogActions>
        </Dialog>
  );
}

