import React, {useEffect, useRef, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { Link, LinearProgress, Button, InputBase, TableRow, TableCell, CircularProgress, IconButton } from  '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as Actions from '../redux/actions';
import { ObjectId } from './utils';
import { BASE_URL, SERVER_URL } from '../api';
import GenerateDocumentDialog from './GenerateDocumentDialog';
import AlertDialog from './AlertDialog';
import CategoriesComponent from "./CategoriesComponent";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        maxHeight: '80vh'
    },
    flexGrow: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    rightContainer: {
        width :'20px',
        flexGrow: 0,
        backgroundColor: 'white'
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    actionLink: {
        margin: '0px 12px',
        cursor: 'pointer'
    },
    actionIcon: {
        width: '12px',
        height: '12px',
        marginLeft: '12px'
    },
    actionButton: {
        width: '12px',
        height: '12px',
    },
    fullRow: {
        width: '100%'
    },
    withMax: {
        '& > div': {
            width: '100%',
        }
    }
}));

export default function DocumentList() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const documents = useSelector(state => state.main.documents);
    const documentsPage = useSelector(state => state.main.documentsPage);
    const docsCount = useSelector(state => state.main.docsCount);
    const questionnaires = useSelector(state => state.main.questionnaires);
    const loadingPhase = useSelector(state => state.main.loadingPhase);

    const [currUser] = useState((localStorage.getItem("current_user") && JSON.parse(localStorage.getItem("current_user")))|| undefined);
    const [renamed, setRenamed] = useState({});
    const [clickedValue, setClickedValue] = useState(undefined);
    const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
    const [newQuestionnaireId, setNewQuestionnaireId] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(undefined);
    const [showDeleteQuestionnaireDialog, setShowDeleteQuestionnaireDialog] = useState(undefined);
    const [deleteQuestionnaire, setDeleteQuestionnaire] = useState(undefined);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const categories = useSelector(state => state.main.categories) || [];
    const organization = useSelector(state => state.main.organization) || undefined;
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandedDocuments, setExpandedDocuments] = useState([]);

    const role =  JSON.parse(localStorage.getItem('current_user')).role;

    function isInArray(id, arr) {
        if(arr.some(e => e.document_id === id)) {
            return true;
        }
        return false;
    }

    let arrDocuments = [];
    if(documentsPage.expandAll){
        for (var i = 0; i < documents.length; i++ ){
            if(isInArray(documents[i].id, questionnaires)){
                arrDocuments.push(documents[i]);
            }
        }
    } else {
        arrDocuments = documents;
    }

    const startNew = async (value, client_name, filename) => {
        const newId = ObjectId();
        await setNewQuestionnaireId(newId);
        const p = {
            id: newId,
            document_id: documents[value].id,
            user_id: currUser.id,
            question_ids: documents[value].question_ids,
            client_name,
            filename
        };
        console.log('startNew with', {p});
        await dispatch(Actions.createQuestionnaire(p));
    };

    const gotoWizard = async (e, value) => {
        window.open(`${SERVER_URL}/wizard/${value}`, "_blank");
    };

    const gotoDownload = async (file_id) => {
        window.open(`${BASE_URL}/files/${file_id}`);
    };

    const handleRename = async (docId, e) => {
        await setRenamed(Object.assign({}, renamed, {[docId]: e.target.value}));
    };
    const syncRenamed = async (index) => {
        const docId = documents[index].id;
        await dispatch(Actions.renameDocument(docId, renamed[docId]));
        await setRenamed(Object.assign({}, renamed, {[docId]: undefined}));
    }
    const columns = [{
        name: "name",
        label: "MasterDoc Name",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return <div className={classes.withMax}><InputBase value={renamed[documents[tableMeta.rowIndex].id] || value} onChange={e=>{handleRename(documents[tableMeta.rowIndex].id, e);}} />
                    {loadingPhase !== 'fetching' && renamed[documents[tableMeta.rowIndex].id] && <Button variant={Link} onClick={e=>{syncRenamed(tableMeta.rowIndex)}}>Sync</Button>}
                    {loadingPhase === 'fetching' && renamed[documents[tableMeta.rowIndex].id] && <CircularProgress size={22} thickness={2.2} />}
                </div>
            },
        }
    }, {
        name: "updated_at",
        label: "Updated At",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return moment.utc(value*1000).local().format('llll');
            },
        }
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return <div className={classes.actionsContainer}>
                    {documents[tableMeta.rowIndex].question_ids.length > 0 && <Link className={classes.actionLink} onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        await setClickedValue(tableMeta.rowIndex);
                        await setGenerateDialogOpen(true);
                    }}>Start new Wizard</Link>}
                    {role.indexOf('admin') >= 0 && <IconButton disabled={!!loadingPhase} aria-label="delete" color="primary" className={classes.deleteIcon} onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        setDeleteCandidate(documents[tableMeta.rowIndex].id);
                        setShowDeleteDialog(true);
                    }}>
                        <Delete />
                    </IconButton>}
                </div>
            }
        }
    }];
    const questionnaireColumns = [{
        name: "id",
        label: "ID",
        options: {
            display: false,
            filter: true,
            sort: true,
        }
    },{
        name: "filename",
        label: "Name",
        options: {
            filter: true,
            sort: true,
        }
    }, {
        name: "updated_at",
        label: "Updated At",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return moment.utc(value*1000).local().format('llll');
            },
        }
    }, {
        name: "email",
        label: "By",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "client_name",
        label: "Client",
        options: {
            filter: true,
            sort: true,
        }
    }, {
        name: 'download',
        label: 'Download',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, iTableMeta, updateValue) => {
                const q = questionnaires.find(q=>q.id === iTableMeta.rowData[0]);
                return <div className={classes.actionsContainer}>
                    {q && q.is_complete &&  <Link className={classes.actionLink} onClick={e=>{gotoDownload(q.file_id);}} >{`${q.filename !== undefined ? q.filename : 'unnamed' }` || 'Download'}</Link>}
                </div>
            }
        }
    }, {
        name: 'goto',
        label: 'Go to Wizard',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, iTableMeta, updateValue) => {
                const q = questionnaires.find(q=>q.id === iTableMeta.rowData[0]);
                return <div className={classes.actionsContainer}>
                    <Link className={classes.actionLink} onClick={e => gotoWizard(e, iTableMeta.rowData[0])}>Go to Wizard</Link>
                    <IconButton disabled={!!loadingPhase} aria-label="delete" color="primary" className={classes.deleteIcon}
                                onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setDeleteQuestionnaire(q.id);
                                    setShowDeleteQuestionnaireDialog(true);
                                }}>
                        <Delete />
                    </IconButton>
                </div>
            }
        }
    }];

    useEffect(() => {
        const loadStuff = async () => {
            await dispatch(Actions.getDocuments(page - 1, 20));
        };
        loadStuff();
    }, [dispatch, page]);

    const handleHide = async () => {
        setShowDeleteDialog(false);
        setShowDeleteQuestionnaireDialog(false);
        setDeleteCandidate(undefined);
        setDeleteQuestionnaire(undefined);
    }

    useEffect(() => {
        if(organization?.id){
            dispatch(Actions.getCategories());
        }
    }, [organization])

    useEffect(() => {
        setExpandedRows(expandedRows);
    }, [questionnaires]);


    return(<div className={classes.root}>
        {loadingPhase === 'fetching' && <LinearProgress style={{width: '100%'}} color="primary" />}
        <AlertDialog
            open={showDeleteDialog}
            onCancel={handleHide}
            onOk={()=> {
                handleHide();
                dispatch(Actions.deleteDocument(deleteCandidate));
            }}
            title={'Are you sure?'}
            message={`Are you sure you want to delete ${deleteCandidate && documents && documents.find(d=>d.id===deleteCandidate).name}?`}
        />
        <AlertDialog
            open={showDeleteQuestionnaireDialog}
            onCancel={handleHide}
            onOk={()=> {
                handleHide();
                dispatch(Actions.deleteQuestionnaire(deleteQuestionnaire));
            }}
            title={'Are you sure?'}
            message={`Are you sure you want to delete ${deleteQuestionnaire && questionnaires && questionnaires.find(q=>q.id===deleteQuestionnaire).name}?`}
        />
        <GenerateDocumentDialog
            documentId={documents[clickedValue]?.id}
            onShare={(sentTo) => {
                console.log('still not sharing: sentTo', sentTo);
            }}
            onClose={async () => {
                await setNewQuestionnaireId(undefined);
                await setClickedValue(undefined);
                await setGenerateDialogOpen(false);
            }}
            open={generateDialogOpen}
        />

        <CategoriesComponent categories={categories}/>
        {loading && <CircularProgress style={{ margin: 'auto' }} />}
        { arrDocuments.length > 0 ?
            <MUIDataTable
                data={arrDocuments}
                columns={columns}
                className={classes.flexGrow}
                options={{
                    page: page - 1,
                    filter: false,
                    download: false,
                    print: false,
                    select: false,
                    search: false,
                    pagination: true,
                    rowsPerPageOptions: [20],
                    rowsPerPage: 20,
                    serverSide: true,
                    count: docsCount,
                    customToolbar: null,
                    selectableRows: false,
                    sort: true,
                    viewColumns: false,
                    expandableRows: true,
                    expandableRowsOnClick: true,
                    elevation: 0,

                    rowsExpanded: expandedDocuments,
                    renderExpandableRow: (rowData, rowMeta) => {
                        const innerData = questionnaires && questionnaires.filter(q => q.document_id === arrDocuments[rowMeta.rowIndex].id);
                        return <TableRow className={classes.fullRow}><TableCell colSpan={questionnaireColumns.length + 1}><MUIDataTable
                            data={innerData}
                            columns={questionnaireColumns}
                            style={{width: '100%', height: '660px', overflowY: 'auto'}}
                            options={{
                                filter: false,
                                customToolbar: null,
                                download: false,
                                print: false,
                                select: false,
                                search: false,
                                pagination: true,
                                selectableRows: false,
                                sort: true,
                                viewColumns: false,
                                elevation: 0,
                            }}
                        />
                        </TableCell></TableRow>
                    },
                    onTableChange: async (action, tableState) => {
                        if (action === 'expandRow') {
                            let arrOpenExtended = [];
                            let expandedDocumentIds = [];

                            if(tableState.expandedRows.data.length) {
                                const expandedRowIndex = tableState.expandedRows.data[tableState.expandedRows.data.length - 1].index;
                                setExpandedDocuments([expandedRowIndex]);
                                arrOpenExtended.push(expandedRowIndex);
                                if (documents[expandedRowIndex]) {
                                    expandedDocumentIds.push(documents[expandedRowIndex].id);
                                }
                                setLoading(true);
                                await Promise.all(expandedDocumentIds.map(docId => dispatch(Actions.getQuestionnaires(docId, ''))));
                                setLoading(false);
                                setExpandedRows(arrOpenExtended);
                            }


                            if (!documentsPage.expandAll && documentsPage.expanded.length !== tableState.expandedRows.data.length) {
                                dispatch(Actions.stateDocumentsExpand(arrOpenExtended));
                            }
                        }
                        if (tableState.page + 1 !== page) {
                            setPage(tableState.page + 1);
                            setExpandedDocuments([]);
                        }
                    }
                }}
            />
            : null }
        <div className={classes.rightContainer}></div>
    </div>);
}
