import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import LegalupRouter from './Router';

// this code here below replaces history in the client if loaded with hashbang and after the original route so server side routes are supported in the client
import { createBrowserHistory } from "history";
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

// document.domain = 'legalup.me';

ReactDOM.render(<LegalupRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
