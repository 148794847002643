
import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import { makeStyles, fade } from '@material-ui/core/styles';
import {Menu, MenuItem, AppBar, InputBase, Toolbar, Typography, IconButton} from '@material-ui/core';
import Supervisor from '@material-ui/icons/SupervisorAccountRounded';
import Face from '@material-ui/icons/FaceRounded';
import TagFacesIcon from '@material-ui/icons/TagFaces';
// import Notifications from '@material-ui/icons/Notifications';
import Logo from './Logo';
import { BASE_URL } from '../api';

import SearchIcon from '@material-ui/icons/Search';
import SettingsDialog from './SettingsDialog';
import * as Actions from "../redux/actions";

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        margin: '18px 40px'
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
        color: 'blue'
    },
    role: {
        marginLeft: '16px',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '400',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#35373d'
    },
    notificationsBadge: {
        margin: '0px 72px'
    },
    search: {
        position: 'relative',
        border: '1px solid rgba(33, 33, 33, 0.1)',
        borderRadius: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.02),
        },
        marginRight: theme.spacing(26),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    grow: {
        flexGrow: 1
    },
    span: {
        display: 'block',
        width: '100%',
    },
    link: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
        display: 'block',
        width: '100%',
    },
    bg: {
        background: 'rgba(33, 150, 243, 0.05)',
    },
}));

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default function Header(props) {
    const organization = useSelector(state => state.main.organization);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const showLogout = localStorage.getItem('access_token') !== null;
    const [redirect, setRedirect] = useState(undefined);
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const [currUser] = useState((localStorage.getItem("current_user") && JSON.parse(localStorage.getItem("current_user"))) || {});
    const userNameWithRole = currUser.role === 'admin' ? `${currUser.username}(${currUser.role})` : currUser.username;
    const [searched, setSearched] = useState("");
    const dispatch = useDispatch();

    const logout = async () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('current_user');
        await setRedirect('/login');
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searched!==''){
                dispatch(Actions.getQuestionnaires('', searched));
            }

            dispatch(Actions.documentsExpand(searched.length > 0));
            //dispatch(Actions.stateDocumentsExpand([]));
            }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searched])

    const requestSearch = (event) => {
        setSearched(event.target.value);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return redirect ? <Redirect to={redirect} /> : (
        <AppBar position="absolute" className={clsx(classes.appBar)}>
            <Toolbar className={classes.toolbar}>
                <Logo title={organization && organization.name} />
                <div className={classes.grow} />
                {/*<div className={classes.search}>*/}
                {/*    <div className={classes.searchIcon}>*/}
                {/*        <SearchIcon />*/}
                {/*    </div>*/}
                {/*    <InputBase*/}
                {/*        placeholder="Search…"*/}
                {/*        classes={{*/}
                {/*            root: classes.inputRoot,*/}
                {/*            input: classes.inputInput,*/}
                {/*        }}*/}
                {/*        inputProps={{ 'aria-label': 'search' }}*/}
                {/*        value={searched}*/}
                {/*        onChange={(e) => requestSearch(e)}*/}
                {/*    />*/}
                {/*</div>*/}
                {/* <Badge color="error" badgeContent={'12'} className={classes.notificationsBadge} variant="dot">
                <Notifications color="primary"  />
            </Badge> */}
                <Typography className={classes.role}>{capitalize(userNameWithRole)}</Typography>
                <IconButton color="inherit">
                    {currUser.role === 'user' &&
                        <TagFacesIcon fontSize="large" color="primary" onClick={(event) => {setAnchorEl(event.currentTarget)}}/>
                    }

                    { currUser.role === 'admin' &&
                        <Face fontSize="large" color="primary" onClick={(event) => {setAnchorEl(event.currentTarget)}}/>
                    }

                    { currUser.role === 'super_admin' &&
                        <Supervisor  fontSize="large" color="primary" onClick={(event) => {setAnchorEl(event.currentTarget)}}/>
                    }

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                            <>
                                <MenuItem onClick={() => {
                                    handleClose();
                                    let newWindow = window.open(`${BASE_URL}/docusign/authenticate`, 'Docusign Login', 'scrollbars=yes,width=640, height=480');
                                    let timer = setInterval(() => {
                                        if (newWindow.closed) {
                                            clearInterval(timer);
                                            alert('Thanks for connecting with docusign');
                                        }
                                    }, 1000);
                                }}>Connect with Docusign</MenuItem>

                                <MenuItem disabled={settingsOpen} onClick={() => {
                                    handleClose();
                                    setSettingsOpen(true);
                                } }>Organization's Settings</MenuItem>


                                { currUser.role !== 'user' &&
                                    <MenuItem className={classes.bg} disabled={settingsOpen} onClick={() => {
                                        handleClose();
                                        setSettingsOpen(true);
                                    } }>
                                        { showLogout && <Link className={classes.link} to='/statistics'><span className={classes.span}>Statistics</span></Link> }
                                    </MenuItem>
                                }

                                <MenuItem disabled={settingsOpen} onClick={() => {
                                    handleClose();
                                    setSettingsOpen(true);
                                } }>
                                    {showLogout && <span className={classes.span}
                                                         onClick={logout}>Logout
                                    </span>
                                    }
                                </MenuItem>
                            </>

                    </Menu>
                </IconButton>
            </Toolbar>
            <SettingsDialog open={settingsOpen} onClose={() => {
                setSettingsOpen(false);
            }}  />
        </AppBar>
    );

};