import * as Actions from '../actions';
import { createReducer } from '@reduxjs/toolkit';
import { BASE_URL } from '../../api';
import { validateEmail } from '../../utils';
import { isRTL } from '../../components/utils';
import {GET_CONTACTS_WIZARD, SIGN_FILE} from "../actions";

// const immerlog = (s) => console.log(JSON.parse(JSON.stringify(s)));

const initialState = {
    id: null,
    filename: null,
    clientName: null,
    fileId: null,
    isComplete: false,
    document: null,
    questions: [], // root questions only - this is not equiv to document.questions
    questionsMap: {},
    defaults: {},
    status: null,
    stagedAnswer: null,
    dirtyIds: [],
    currentQuestionIndex: 0,
    fileDownloadLink: '',
    has_signatures: false,
    signature_type: null,
    final_format: null,
    error: '',
    loadingPhase: '',
    wizardId: '',
    contact_id: '',
    contacts:{},
    signError:'',

    // this state keys will be question ids
    // for simple questions the value will { question_id: 'zzz' value: 'xxx', other_value: 'yyy' } 
    // for multi it will have the form { children: [] }
    // for multi repeating [{ children: []}]
};

const evalQuestionCondition = (question, state) => {
    console.log(`eval, passed q=${JSON.stringify(question, null, 4)}`);
    // immerlog({question});
    const { conditions } = question;
    if (!conditions || conditions.length === 0) {
        return true;
    }
    
    let idx = 0;
    let arrayIdx = 0;
    const arranged = []; //arranged: array of arrays. each inside array is one or more items that do AND between them.
    while (idx < conditions.length) {
        if (arranged[arrayIdx] === undefined) {
            arranged[arrayIdx] = [conditions[idx]];
            idx++;
        } 
        while(idx < conditions.length && conditions[idx].operator && conditions[idx].operator.toLowerCase() === 'and') {
            arranged[arrayIdx].push(conditions[idx]);
            idx++;
        }
        arrayIdx++;
    } 
    let theBigOR = false;
    for (let i = 0; i < arranged.length; i++) {           
        //if one full inner array is true we are out.
        let smallAND = true;
        for (let j = 0; j < arranged[i].length; j++) {
            const condition = arranged[i][j];
            const conditionedQuestion = state.questionsMap[condition.question_id];
            const isConditionedQuestionChild = conditionedQuestion && conditionedQuestion.parent_id !== undefined;
            const parentAnswer = isConditionedQuestionChild ? state[conditionedQuestion.parent_id] : undefined;
            // immerlog({isConditionedQuestionChild, conditionedQuestion, parentAnswer, condition});
            const answer = isConditionedQuestionChild ? parentAnswer && parentAnswer.children?.find(c => c.question_id === conditionedQuestion.id) :
                state[condition.question_id];
            if (condition.value.toLowerCase() === 'answered') { //regular answers
                if (!answer || (condition.type === 'is' && (answer.value === '' || answer.value === undefined)) || 
                    (condition.type !=='is' && answer.value !== '' && answer.value !== undefined)) {
                        smallAND = false;
                        break;
                }
            } else { //multiple choice answers
                // console.log('multip: *********${JSON.stringify(answer)}***********`);
                if (!answer || (condition.type === 'is' && answer && answer.value.trim().indexOf(condition.value.trim()) < 0) ||
                    (condition.type !== 'is' && answer && (answer.value.trim().indexOf(condition.value.trim()) >= 0 || !answer.value))) {
                        smallAND = false;
                        break;
                }
            }
        }
        if (smallAND) {
            theBigOR = true;
            break;
        }
    }

    if(!theBigOR){
        if (!question.is_multi) {
            const emptyAnswer = {
                question_id: question.id,
                value: ''
            };
            saveAnswerIfSkipQuestion(state, { answer: emptyAnswer });
        } else if (question.is_multi && question.repeat_type === 'do_no_repeat'){
            const newChildrenArr = []
            const emptyAnswer = {
                question_id: question.id,
                children: []
            };
            newChildrenArr.push(emptyAnswer);
            saveAnswerIfSkipQuestion(state, { answer: emptyAnswer });
        } else {
            const newChildrenArr = []
            const emptyAnswer = {
                question_id: question.id,
                children: []
            };
            newChildrenArr.push(emptyAnswer);
            saveAnswerIfSkipQuestion(state, { answer: newChildrenArr });
        }
    }
    return theBigOR;
}

const validateAnswer = (question, answer, state) => {
    const doSingle = (_question, _answer) => {
        console.log(`DOSINGLE ${JSON.stringify(_question)} ,${JSON.stringify(_answer)}`);
        const val = _answer ? _answer.other_value || _answer.value || '' : '';
        const RTL = isRTL(_question.text);
        _answer.valid = true;
        if (_question.type === 'number') {
            if (_question.min_value !== undefined && _question.max_value !== undefined) {
                if (val < _question.min_value || val > _question.max_value) {
                    _answer.error = (RTL ? (_question.min_value !== undefined && _question.max_value !== undefined) ? 
                        `(בין ${_question.min_value} ל ${_question.max_value})` : ''
                        : (_question.min_value !== undefined && _question.max_value !== undefined) ? 
                        `(between ${_question.min_value} and ${_question.max_value})` : '');
                    _answer.valid = false;
                } else {
                    _answer.error = '';
                    _answer.valid = true;
                }
            }
        } else if (_question.type === 'email') {
            const valid = validateEmail(val);
            console.log({valid});
            if (!valid) {
                _answer.error = RTL ? 'כתובת דואל שגויה' : 'Invalid email address';            
            } else {
                _answer.error = '';
            }
            _answer.valid = valid;
        }         
        _answer.missing = _question.mandatory ? !val.trim() : false;
    };
    if (question.is_multi) {
        const handleMultiChild = (mAnswer) => {
            mAnswer.valid = true;
            mAnswer.missing = false;

            //decide if this is a party q.
            const partyQuestion = question.children && question.children.filter(q => q.read_only)[0];
            for (let i = 0; i < question.children.length; ++i) {  
                const currID = question.children_ids[i];
                console.log({currID});
                const childQ = state.questionsMap[currID];
                const realChild = mAnswer.children.find(c => c.question_id === currID);
                const childAnswer = Object.assign({question_id: currID, text: ""}, realChild, {valid: true});                
                
                // if this is a party q dont validate the unconditioned answers and the read only q as well
                console.log({question, childAnswer, childQ, mAnswer});
                if (partyQuestion && childQ) {
                    if (childQ && partyQuestion.id === childQ.id) continue;
                    const partyValue = partyQuestion ? mAnswer.children[0].value : '0';
                    if (!(childQ.conditions[0].question_id === partyQuestion.id && 
                        childQ.conditions[0].value === partyValue.toString())) continue;

                    childAnswer.missing = childQ && childQ.mandatory ? (!childAnswer.other_value && !childAnswer.value) : false;
                    mAnswer.missing = mAnswer.missing || childAnswer.missing;
                } 
                if(childQ) {
                    doSingle(Object.assign({}, childQ), childAnswer);
                    mAnswer.missing = mAnswer.missing || childAnswer.missing;
                }
                console.log({mAnswer});
                if (childAnswer && !childAnswer.valid) {
                    mAnswer.valid = false;
                    if (!realChild) {
                        mAnswer.children.push(childAnswer);
                    } else {
                        const idx = mAnswer.children.findIndex(c => c.question_id === currID);
                        mAnswer.children[idx] = Object.assign({}, mAnswer.children[idx], 
                            {error: childAnswer.error, valid: childAnswer.valid, missing: childAnswer.missing});
                    }                    
                } 
            }
            return mAnswer;
        };

        if (question.repeat_type !== 'do_no_repeat') { //this is an array of multies
            for (let i = 0; i < answer.length; i++) {
                answer[i] = handleMultiChild(Object.assign({}, answer[i]));
                if (answer[i].missing) answer.missing = true;
            }
        } else {
            handleMultiChild(answer);
        }
    } else {        
        doSingle(Object.assign({}, question), answer);
    }
};

const processAnswer = (state, question, answer) => {
    console.log(`Process answer for qid: ${question.id}`);
    if (state[answer.question_id]) {
        const v = state[answer.question_id];
        if (Array.isArray(answer)) {
            v.push(answer);
            validateAnswer(question, v, state);
        } else {
            state[answer.question_id] = [v, answer];
            validateAnswer(question, [v, answer], state);
        }
    } else {        
        state[question.id] = answer;
        validateAnswer(question, answer, state);
    }
};

const saveAnswer = (state, action) => {
    const answer = action.answer;
    console.log({answer});
    // if we get an array this means it's a repeating field and then we grab the id from the first inner multi q.
    const id = Array.isArray(action.answer) ? answer[0].question_id : answer.question_id;
    console.log({id});
    const q = state.questionsMap[id]; 
    //state.questions.find(q=>q.id === id);
    validateAnswer(q, answer, state);

    state[id] = answer;
    if (state.dirtyIds.indexOf(id) === -1) {
        state.dirtyIds.push(id);
    }
};

const saveAnswerIfSkipQuestion = (state, action) => {
    const answer = action.answer;
    const id = Array.isArray(action.answer) ? answer[0].question_id : answer.question_id;
    console.log({id});
    state[id] = answer;
    if (state.dirtyIds.indexOf(id) === -1) {
        state.dirtyIds.push(id);
    }
};

const handleMissingQuestion = (q, state) => {
    let answer = state[q.id];
    console.log(`handling missing question`, {q, answer});
    if (q.is_multi) {
        const partyQuestion = q.children && q.children.filter(qq => qq.read_only)[0];
        const handleMultiAnswer = a => {                        
            for (let j = 0; j < q.children.length; ++j) {
                const _q = q.children[j];
                const _a = a.children && a.children.find(c => c.question_id === _q.id);

                const partyValueObject =  partyQuestion ? a.children.find(q=>q.question_id === partyQuestion.id) : {}; 
                //skip the unconditioned questions
                if (partyQuestion && (j > 0 && _q && _q.conditions && _q.conditions[0].question_id === partyQuestion.id)) {
                    const cond = _q.conditions[0];
                    if (cond.type === "is") {
                        if (cond.value.toString() !== partyValueObject.value) continue;
                    } else {
                        if (cond.value.toString() === partyValueObject.value) continue;
                    }
                }

                if (!_a) {
                    if (_q.default_value) {
                        if (!a.children) a.children = [];
                        a.children.push({ question_id: _q.id, value: _q.default_value, missing: false });
                    } else if (_q.mandatory) {
                        if (!a.children) a.children = [];
                        a.children.push({ question_id: _q.id, value: '', missing: true });
                        a.missing = true;
                    }
                } else if (!_a.other_value && !_a.value) {
                    if (_q.default_value) {
                        _a.missing = false;
                        _a.value = _q.default_value;
                    } else if (_q.mandatory) {
                        _a.missing = true;
                        _a.value = '';
                        a.missing = true;
                    }                                
                }
                a.missing = a.missing || _a && _a.missing;
            }
            // state[q.id] = a;
            return a.missing;
        };
        if (answer) {
            if (Array.isArray(answer)) { 
                for (let j = 0; j < answer.length; ++j) {
                    handleMultiAnswer(answer[j]);
                }
            } else {
                handleMultiAnswer(answer);
            }
        } else {
            //no answer for the multi q
            const a = { question_id: q.id, children: [], missing: false };
            state[q.id] = (q.repeat_type === 'do_no_repeat') ? a : [a];
            console.log({a});
            console.log('next stagE:', state[q.id])
            handleMultiAnswer(a);
        }
    } else {
        if (q.default_value) {
            answer = { question_id: q.id, missing: false, value: q.default_value };
        } else if (q.mandatory && !answer) {
            answer = { question_id: q.id, missing: true, value: '' };
        }
    }
};

const gotQuestionnaire = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
        if (action.success) {
            if (state.document && state.document.question_ids) { //clear first
                for (let i = 0; i < state.document.question_ids; ++i) {
                    state[state.document.question_ids[i]] = null;
                }
            }
            state.id = action.questionnaire.id;
            state.document = action.document;
            state.filename = action.questionnaire.filename;
            state.fileId = action.questionnaire.file_id;
            state.fileDownloadLink = state.fileId !== '' && state.fileId !== undefined ? `${BASE_URL}/files/${action.questionnaire.file_id}` : '';
            state.isComplete = action.questionnaire.is_complete;
            state.clientName = action.questionnaire.client_name;
            state.has_signatures = action.questionnaire.has_signatures;
            state.signature_type = action.questionnaire.document.signature_type;
            state.final_format = action.questionnaire.document.final_format;
            // set default values as answers as well
            // map questions by id
            // collect root questions
            const defaults = {};
            const questionsMap = {};
            const rootQuestions = []; 
            const questions = (state.document && state.document.questions) || [];
            state.questions = rootQuestions;
            state.questionsMap = questionsMap;
            state.defaults = defaults;
            state.contact_id =  action.questionnaire.contact_id;

            for (let i = 0; i < questions.length; ++i) {
                const q = questions[i];
                questionsMap[q.id] = q;
                for (let j = 0; j < q.children.length; j++) {
                    const cq = q.children[j];
                    // if (!cq.read_only) 
                    questionsMap[cq.id] = cq;
                }
            }

            const saveQuestionDefaultsToState = q => {
                if (!q.parent_id) {
                    rootQuestions.push(q);
                }
                if (q.default_value && state[q.id] === undefined) {
                    state[q.id] = { value: q.default_value };
                    defaults[q.id] = q.default_value;
                }
                if (q.is_multi) {
                    const saveOneDefault = (children, child) => {
                        children.push( { question_id: child.id, value: child.default_value });
                        defaults[child.id] = child.default_value;
                    };
                    
                    if (q.repeat_type === 'do_no_repeat') {
                        const children = [];
                        for (let j = 0; j < q.children.length; ++j) {
                            const child = q.children[j];
                            if (child.default_value && (!state[q.id] || (state[q.id].children && state[q.id].children.find(c=>c.id === child.question_id) === undefined))) {
                                saveOneDefault(children, child);
                            }
                        }
                        if (children.length > 0) {
                            state[q.id] = { children };
                        }
                    } else {
                        const repeatingObjects = state[q.id] || [];
                        for (let a = 0; a < repeatingObjects.length; ++a) {
                            const repeatingA = repeatingObjects[a];
                            const currChildren = [];
                            for (let j = 0; j < repeatingA.children.length; j++) {
                                const child = repeatingA.children[j];
                                if (child.default_value && state[q.id] && state[q.id].children.find(c=>c.id === child.question_id) === undefined) {
                                    saveOneDefault(currChildren, child);
                                }
                            }
                            if (currChildren.length > 0) {
                                state[q.id][a] = {children: currChildren};
                            }
                        }                        
                    }
                }
            }
            if (action.questionnaire.answers.length > 0 ) {
                const roots = action.document.questions.filter(q=>!q.parent_id);    
                for (let i = 0; i < roots.length; ++i) {
                    const rootQ = roots[i];
                    const _answer = rootQ.repeat_type === 'do_no_repeat' ? 
                        action.questionnaire.answers.find(a => a.question_id === rootQ.id) : 
                        action.questionnaire.answers.filter(a => a.question_id === rootQ.id);
                    if (!_answer || (Array.isArray(_answer) && _answer.length === 0)) continue;
                    const _answerId = Array.isArray(_answer) ? _answer[0].question_id : _answer.question_id;
                    if (Array.isArray(_answer) && _answer[0].question_id && _answer[0].question_id !== 'undefined' || 
                        _answer && _answerId !== 'undefined') {
                        processAnswer(state, questionsMap[_answerId], _answer);
                    }
                }
            }    

            for (let i = 0; i < questions.length; ++i) {
                const q = state.document.questions[i];
                saveQuestionDefaultsToState(q);
                if (q.is_multi) {
                    for (let j = 0; j < q.children.length; ++j) {
                        saveQuestionDefaultsToState(q.children[j]);
                    }
                }
            }

            // handle .missing of all levels
            for (let i = 0; i < rootQuestions.length; ++i) {
                const q = rootQuestions[i];
                handleMissingQuestion(q, state);
            }

            state.status = '';
        } else {
            state.status = 'error';
            state.error = action.error;
        }
    } else {
        state.loadingPhase = 'fetching';
        state.status = 'loading';
        state.error = '';
    }
};

const postAnswers = (state, action) => {
    if (typeof action.success === 'boolean') {
        if (action.success) {
            const questions = state.questions;
            if (action.back) {
                let nextIdx = Math.max(state.currentQuestionIndex - 1, 0);
                let shouldShow = false;
                while (nextIdx >= 0 && !shouldShow) {
                    shouldShow = evalQuestionCondition(questions[nextIdx], state);
                    if (!shouldShow) {
                        console.log(`SKipping (back) question ${questions[nextIdx].title} as conditions were not met.`);
                        nextIdx--;
                    }
                }
                state.currentQuestionIndex = nextIdx;        
            }
            else if (action.index >= 0) {
                state.currentQuestionIndex = action.index;
            } else {
                //after each answer we jump over all "hidden" questions
                let nextIdx = state.currentQuestionIndex + 1;
                let shouldShow = false;
                while(nextIdx < questions.length && !shouldShow) {
                    shouldShow = evalQuestionCondition(questions[nextIdx], state);
                    console.log('after eval:', shouldShow);
                    if (!shouldShow) {
                        console.log(`SKipping question ${questions[nextIdx].title} as conditions were not met.`);
                        nextIdx++;
                        // console.log(`nextIndex=${nextIdx}`);
                    }
                }
                state.currentQuestionIndex = nextIdx;
                // console.log(`switched TO:${state.currentQuestionIndex}`);
            }
            state.dirtyIds = [];
            state.status = '';
            state.statusNext = '';
        } else {
            state.status = 'error';
            state.error = action.error;
        }
    } else {
        state.status = 'loading';
        state.statusNext = 'loading';
        state.error = '';
    }
};


const getContactsWizard = (state, action) => {
    if(action.success === true) {
        state.contacts = action.contacts.map;
    }
};


const postDocuments = (state, action) => {
    if (typeof action.success === "boolean") {
        state.loadingPhase = '';
        if(action.success === true) {
            state.wizardId = action.doc.id;
        } else {
            state.error = 'Сheck the document id, since we can\'t find it';
        }

    } else {
        state.loadingPhase = 'fetching';
    }
};

const downloadFile = (state, action) => {
    if (typeof action.success === 'boolean') {
        if (action.success) {
            const localUrl = window.location.href;
            if(localUrl.indexOf(action.file.questionnaire_id) != -1){
                state.status = '';
                state.fileId = action.file.id;
                state.has_signatures = !!action.file.has_signatures;
                state.fileDownloadLink = `${BASE_URL}/files/${action.file.id}`;
            }
        } else {
            state.status = 'error';
            state.error = action.error;
        }
    } else {
        state.fileDownloadLink = ''
        state.status = 'generating';
    }

};
const downloadFileSignature = (state, action) => {
    if (typeof action.success === 'boolean') {
        if (action.success) {
            const localUrl = window.location.href;
            if(localUrl.indexOf(action.file.questionnaire_id) != -1){
                state.status = '';
                state.fileId = action.file.id;
                state.has_signatures = !!action.file.has_signatures;
                state.fileDownloadLink = `${BASE_URL}/files/${action.file.id}`;
            }
        } else {
            state.status = 'error';
            state.error = action.error;
        }
    } else {
        state.fileDownloadLink = ''
        state.status = 'generating';
    }

};

const resetQuestionnaire = (state, action) => {
    state.currentQuestionIndex = 0;
}

const signFileById = (state, action) => {
    if(action.success === false){
        console.log('--signFileById---', action)
        state.signError = `Error - ${action.error}`;
    }
    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
}


const handlers = {};
handlers[Actions.GET_QUESTIONNAIRE] = gotQuestionnaire;
handlers[Actions.CREATE_QUESTIONNAIRE] = gotQuestionnaire;
handlers[Actions.POST_ANSWERS] = postAnswers;
handlers[Actions.POST_DOCUMENTS] = postDocuments;
handlers[Actions.GET_CONTACTS_WIZARD] = getContactsWizard;
handlers[Actions.SAVE_ANSWER] = saveAnswer;
handlers[Actions.DOWNLOAD_FILE] = downloadFile;
handlers[Actions.DOWNLOAD_FILE_SIGNATURE] = downloadFileSignature;
handlers[Actions.RESET_QUESTIONNAIRE] = resetQuestionnaire;
handlers[Actions.SIGN_FILE] = signFileById;

export default createReducer(initialState, handlers);