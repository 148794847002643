import React, {useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Button, Dialog, Box, DialogTitle, DialogContent, LinearProgress, DialogActions, TextField}  from '@material-ui/core';
import { useSelector, useDispatch} from 'react-redux';
import  * as Actions  from '../redux/actions';

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: 'white',
        backgroundColor: '#1274E4',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#1060BB'
      },
    },
}))(Button);

const useStyles = makeStyles(theme => ({
    root: {
    },
    paper: {
        margin: '0px',
        width: '400px',
        height: '260px',
        overflowY: 'auto',
    },
    title: {
        fontFamily: 'Assistant',        
        color: 'white',
        backgroundColor: '#1274E4',
        height: '28px',
        '& > .MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 700,
            color: 'white'
        }
    },
    secondary: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        letterSpacing: '0px',
        margin: '10px 0px',
        color: '#35373D'
    },
    remark: {
        fontSize: '10px',
        fontFamily: 'Assistant',
        color: 'rgba(60, 60, 60, 0.5)'
    },
    button: {
        width: '100%',
        margin: '13px 0px',
        height: '38px',
        borderRadius: '25px',
    },
    label: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0px',
        color: '#35373D'
    },
    link: {
        fontSize: '13px',
        fontWeight: 400,
        color: '#57606B'
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin : '24px 0px'
    },
    preview: {
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Assistant',
        letterSpacing: '0px',
        color: '#1274E4'
    },
    explanation: {
        textAlign: 'left',
        font: 'Assistant',
        fontSize: '11px',
        letterSpacing: '0px',
        color: '#57606B',
        margin: '6px 0px'
    },
}));

export default function SettingsDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {open, onClose} = props;
    const organization = useSelector(state => state.main.organization);
    const loadingPhase = useSelector(state => state.main.loadingPhase);
    const [dirty, setDirty] = useState(false);
    const [monday_key, setMonday] = useState(organization && organization.monday_key);

    const currentMondayKey = dirty ? monday_key : organization && organization.monday_key;

    const onSave = () => {
        const updateDoc = Object.assign({}, organization, {monday_key});
        dispatch(Actions.updateOrganization(updateDoc));
        setDirty(false);
    };

    const handleClose = async () => {
        await onClose();
    };
    return (
        <Dialog 
            onClose={handleClose} 
            aria-labelledby="simple-dialog-title" 
            open={open}
            className={classes.root} 
            scroll='paper'
            classes={{paper: classes.paper}}>
            <DialogTitle id="simple-dialog-title" classes={{root: classes.title}} >{organization && organization.name} Settings</DialogTitle>            
            <DialogContent>
                <TextField fullWidth multiline name="mondayKey" label="Monday Key" disabled={organization === undefined}
                    value={currentMondayKey} 
                    onChange={(e) => { setMonday(e.target.value); setDirty(true); }}/>
            </DialogContent>
            <DialogActions>
                {loadingPhase !== '' && <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>}
                {loadingPhase === '' && dirty && <StyledButton className={classes.button} onClick={onSave}>Save</StyledButton>}
                {!dirty && <StyledButton className={classes.button} onClick={onClose}>Close</StyledButton>}
            </DialogActions>
        </Dialog>
  );
}

