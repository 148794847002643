import React, { useState,  useEffect, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import  {Link, useParams} from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {InputBase, AppBar, Button, Toolbar, Typography, LinearProgress} from '@material-ui/core';
import Logo from './Logo';
import LogoLoad from './LogoLoading';
import WizardProgress from './WizardProgress';
import WizardQuestionView from './WizardQuestionView';
import WizardMultipleQuestionView from './WizardMultipleQuestionView';
import * as Actions from '../redux/actions';
import { isRTL } from './utils';
import { BASE_URL } from '../api';
import ArrowDropDown from '@material-ui/icons/KeyboardArrowDown';
import { isMobile } from 'react-device-detect';

import backgroundImage from '../assets/pattern.png'; 
import DocumentReadyDialog from './DocumentReadyDialog';

const StyledButton = withStyles(theme => ({
    root: {
        width: '100px',
        height: '42px',
        fontFamily: 'Assistant',
        fontSize: '16px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '21px',
        color: 'white',
        backgroundColor: '#1274e4',
        '&:hover': {
            backgroundColor: '#1060BB'
      },
    },
}))(Button);

const WhiteStyledButton = withStyles(theme => ({
    root: {
        width: '100px',
        height: '42px',
        fontFamily: 'Assistant',
        fontSize: '16px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '21px',
        color: '#1060BB',
        border: '1px solid #1060BB',
        backgroundColor: 'transparent',
        '&:hover': {
            fontSize: '17px'
        },
        '&:disabled': {
            borderColor: 'rgba(0, 0, 0, 0.26)'
        }
    },
}))(Button);


const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'round',
        position: 'relative',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 0,
        marginRight: '24px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8px 12px',
        justifyContent: 'flex-end'
    },
    progressRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '32px 42px 12px 0px',
        justifyContent: 'flex-end'
    },
    appBar: {
        borderBottom: '1px solid rgba(25, 25, 25, 0.06);',
        backgroundColor: '#fff'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '18px',
        marginBottom: '8px'
    },
    poweredRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '6px'
    },
    docName: {
        fontFamily: 'Assistant',
        fontSize: '18px',
        fontWeight: 500,
    },
    alignRight: {
        textAlign: 'right'
    },
    rightSide: {
        display: 'flex',
        flexDirection: 'column',
        width: '260px',
        height: '80vh',
        maxHeight: '630px',
        backgroundColor: 'white',
        marginLeft: '40px',
        textAlign: 'center',
        padding: '0 22px',
        paddingTop: '24px',
        boxSizing: 'border-box',
        borderRadius: '8px',
    },
    contentPart: {
        display: 'flex',
        width: '600px',
        flexDirection: 'column',
        borderRadius: '8px',
        background: '#FFF',
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: isMobile ? '320px' : '1000px',
        justifyContent: 'center',
        height: '80vh',
        // flexGrow: 1,
        margin: 'auto',
        marginTop: '32px',
    },
    mapWizardDisable: {
        minHeight: '50px',
        marginBottom: '20px',
        border: '1px solid #EAEAEA',
        borderRadius: '10px',
        alignContent: 'center',
        flexGrow: '1',
        fontSize: '14px',
    },
    mapWizardActive: {
        cursor: 'pointer',
        minHeight: '50px',
        marginBottom: '20px',
        border: '1px solid #EAEAEA',
        borderRadius: '10px',
        alignContent: 'center',
        color: '#000',
        background: '#F4F4F5',
        flexGrow: '1',
        fontSize: '14px',
    },
    mapWizardInline: {
        display: "flex",
        alignItems: 'center',
        position: "relative",
    },
    mapWizardInlineLeft: {
        width: '40px',
        flexShrink: 0,
        height: '50px',
        alignContent: 'center',
        marginBottom: '24px',
        textAlign: "left",
        '& :after': {
            border: '1px solid #000',
            height: '50px',
            width: '2px',
            content: '',
            display: 'block',
        },
    },
    mapWizardInlineLeftAfter: {
        position: 'absolute',
        left: '12px',
        bottom: '-14px',
        height: '52px',
        transform: 'translateX(-50%)',
        borderLeft: '1px solid #D0D0D0',
        width: '0px',
    },
    buttonBlock: {
    },
    rightSideOrientLeft: {
        order: 1,
        marginLeft: 0,
        marginRight: '40px',
    },
    contentPartOrientRight: {
        order: 2,
    },
    button: {
        margin: '0px 4px'
    },
    buttonContainer: {
        paddingRight: '18px'
    },
    powered: {
        fontFamily: 'Assistant',
        fontSize: '11px',
        alignSelf: 'center',
        marginRight: '4px'
    },
    poweredLink: {
        fontFamily: 'Assistant',
        fontSize: '11px',
        alignSelf: 'center',
        fontWeight: '500'
    },
    flexGrow: {
        flexGrow: 1
    },
    currentContainer: {
        maxHeight: '472px',
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: '1 1 auto',
    },
    generationContainer: {
        marginTop: '32px',
        alignSelf:'center',
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        alignItems: 'center'
    },
    generationText: {
        fontFamily: 'Assistant',
        fontSize: '22px'
    },
    forAddin:{
        width: '320px',
    },
}));

export default function MainWizardView(props) {
    const classes = useStyles();
    let { questionnaireId } = useParams();
    const questionnaire = useSelector(state => state.questionnaire);
    const { 
        document, 
        questions, 
        status,
        statusNext,
        currentQuestionIndex,
        fileDownloadLink
    } = questionnaire;

    const mainRef = useRef(null);
    const lastElement = useRef();
    const observer = useRef();

    const fileSignLink = BASE_URL + '/docusign/authenticate';
    const currentQuestion = (questions && questions.length > 0 && questions[currentQuestionIndex]) || undefined;
    const [dirtyName, setDirtyName] = useState((document && document.name) || undefined);
    const [documentReadyDialogOpen, setDocumentReadyDialogOpen] = useState(false);
    const dispatch = useDispatch();
    // const [willGenerate, setWillGenerate] = useState(false);
    const [currUser] = useState((localStorage.getItem("current_user") && JSON.parse(localStorage.getItem("current_user"))) || undefined);
    const contactsWizard = useSelector(state => state.questionnaire.contacts);

    const docName = document && document.name ? document.name : 'N/A';

    const afterSignText = currUser ? `${docName} was sent to the signer for completion` :
        `${docName} was sent for review`;
    console.log({document, docName, afterSignText});
    const RTL = document && document.name && isRTL(document.name);
    const finishString = RTL ? 'סיום' : 'Finish';
    const nextString = RTL ? 'הבא' : 'Next';
    const backString = RTL ? 'חזרה' : "Back";
    const skipString = RTL ? 'דלג/י' : "Skip";

    const [bottomShowWhenScrool, setBottomShowWhenScrool] = useState(false);
    const [mapWizardWhenWeClick, setMapWizardWhenWeClick] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const forAddin = searchParams.get('forAddin');

    useEffect( () => {
        Actions.initGlobalWS(dispatch)
    }, [] );

    useEffect(() => {
        if (status !== 'loading' && status !== null ){
            let callback = function (entries, observer) {
                if (entries[0].isIntersecting){
                    setBottomShowWhenScrool(true)
                }
            }
            observer.current = new IntersectionObserver(callback);
            observer.current.observe(lastElement.current);
        }

    }, [questionnaire]);


    const handleNextClick = async () => {
        dispatch(Actions.postAnswers());
        mainRef.current.scrollTo(0, 0);
        // console.log('NEXT CLICKED', currentQuestionIndex, questions.length);
        // if (currentQuestionIndex + 1 === questions.length) 
        //     await setWillGenerate(true);
        if(questionnaire.contact_id){
            dispatch(Actions.getContactsWizard(questionnaire.contact_id));
        }
        setBottomShowWhenScrool(false)
    }
    
    const handleBackClick = async () => {
        dispatch(Actions.postAnswers(true));
        mainRef.current.scrollTo(0, 0);
        if(questionnaire.contact_id){
            dispatch(Actions.getContactsWizard(questionnaire.contact_id));
        }
    }

    const onAnswerChange = (answer) => {
        console.log('-------onAnswerChange - answer-------',JSON.stringify(answer) )
        dispatch(Actions.saveAnswer(answer));
    };
    
    const handleDocNameBlur = async (e) => {
        e.persist();
        await setDirtyName(e.target.value);
    };

    useEffect(() => {
        console.log('initializing main wizard view')
        if ((questionnaire.status === '' || questionnaire.status === null) && document === null) {
            dispatch(Actions.openQuestionnaire(questionnaireId));
        } 
    }, [questionnaire, dispatch, document, questionnaireId]);

    useEffect(() => {
        console.log('currentQuestionChanged to', currentQuestionIndex);
        if (questions && questions.length > 0 && currentQuestionIndex + 1 > questions.length) {
            dispatch(Actions.downloadFile());
        } 
    }, [currentQuestionIndex, dispatch, questions]);

    useEffect(() => {
        console.log({questionnaire});
        if (fileDownloadLink !== '') 
            setDocumentReadyDialogOpen(true);

    }, [fileDownloadLink])

    useEffect(() => {
        if(questionnaire.contact_id){
            dispatch(Actions.getContactsWizard(questionnaire.contact_id));
        }
    }, [questionnaire.contact_id]);

    const currentAnswer = questionnaire && currentQuestion && questionnaire[currentQuestion.id];    
    //hack: if this is party question and the hidden individual/corporation is not selected next is enabled

    const partyQ = currentQuestion && currentQuestion.children.filter(c=>c.read_only)[0];
    const partyAnswer = partyQ ? currentQuestion.repeat_type === 'do_no_repeat' ? currentAnswer.children[0]: currentAnswer[0].children[0]  : undefined;
    const enableNextIfNotSelectedPartyHack =  (partyQ && partyAnswer.missing);

    let nextDisabled = currentAnswer &&  currentQuestion.repeat_type !== 'do_no_repeat' ?
        currentAnswer && Array.isArray(currentAnswer) && currentAnswer.filter(inner => inner.missing).length > 0: //repeat question
        currentAnswer?.children?.length ? currentAnswer?.missing  :
        // last one is single question missing and madatory;
        currentQuestion && currentQuestion.mandatory && !currentAnswer;
    
    console.log({enableNextIfNotSelectedPartyHack,currentQuestion, nextDisabled, currentAnswer});

    const filteredDataMapWizard = document?.questions.filter(item => item.question_map_title && item.question_map_title.trim() !== '');

    const findIndexById = (currentQuestion) => {
        if(document?.questions.length && currentQuestion?.id ){
            return document?.questions.findIndex(question => question.id === currentQuestion.id);
        }
    };

    const checkQuestionNext = (currentQuestion, index) => {
        const currentAnswerForMap = questionnaire && currentQuestion && questionnaire[currentQuestion.id];
        let nextDisabledForMap = currentAnswerForMap &&  currentQuestion.repeat_type !== 'do_no_repeat' ?
            currentAnswerForMap && Array.isArray(currentAnswerForMap) && currentAnswerForMap.filter(inner => inner.missing).length > 0:
            currentAnswerForMap?.children?.length ? currentAnswerForMap?.missing  :
                // last one is single question missing and madatory;
                currentQuestion && currentQuestion.mandatory && !currentAnswerForMap;

        const checkItemAndCurrentItem = checkCurrentQuestionAndActiveMapWizard(currentQuestion);
        if(checkItemAndCurrentItem) {
            return false;
        }

        if(mapWizardWhenWeClick) {
            const findIndexCurrentQuestionInFilteredQuestions = document?.questions.findIndex((i)=>i.id === currentQuestion.id)
            if(currentQuestionIndex < findIndexCurrentQuestionInFilteredQuestions) {
                return true;
            }
        }

        return nextDisabledForMap;
    }

    const index = findIndexById(currentQuestion);

    const handleClickMapWizard = (item, disable) => {
        if (!disable) {

            const findIndexCurrentQuestionInFilteredQuestions = filteredDataMapWizard.findIndex((i)=>i.id === item.id)
            if(findIndexCurrentQuestionInFilteredQuestions !==-1 ) {
                setMapWizardWhenWeClick(true)
            }

            const index = findIndexById(item);
            dispatch(Actions.postAnswersMap(index));
        }
    };

    const checkCurrentQuestionAndActiveMapWizard = (item) => {
        if (item.id === currentQuestion?.id) {
            return true;
        }
    };

    return (<div className={classes.root}>
            <AppBar position="sticky" color="default" elevation={0} className={classes.appBar}>
                <Toolbar>
                    <Logo style={{'maxWidth': '140px', 'marginRight': '20px'}} />
                    {RTL && <div className={classes.flexGrow} />}
                    <InputBase 
                        classes={{
                            root: classes.docName,
                            input: RTL ? classes.alignRight : undefined,
                        }}
                        value={dirtyName ? dirtyName : document ? document.name : ''} 
                        onBlur={handleDocNameBlur} 
                        onChange={e => {setDirtyName(e.target.value);}} />
                </Toolbar>
            </AppBar>

            <div className={forAddin ? classes.questionContainer + ' ' + classes.forAddin : classes.questionContainer }>
                <div className={RTL ? classes.contentPart : classes.contentPart + ' ' + classes.contentPartOrientRight }>
                    {status === 'loading' && statusNext  !== 'loading' &&
                        <div className="load">
                            <LogoLoad className="logo" />
                            <div className="loader"></div>
                        </div>
                    }

                    {statusNext  === 'loading' &&
                        <div className={classes.generationContainer}>
                            <LinearProgress style={{width: '100%' }}/>
                        </div>
                    }

                    { status === 'generating' &&
                        <div className={classes.generationContainer}>
                            <LinearProgress style={{width: '100%' }}/>
                            <Typography className={classes.generationText}>Document generation in process</Typography>
                        </div>
                    }

                    {currentQuestion && <div className={classes.progressRow}>
                        <WizardProgress completed={(questions.length > 0 && (currentQuestionIndex) * 100 / questions.length) || 0} />
                    </div>}
                    {/*<div>{index+1}</div>*/}
                    {/*<div>{currentQuestion?.id}</div>*/}

                    <div className={classes.currentContainer} id="qBase" ref={mainRef}>
                            {currentQuestion && !currentQuestion.is_multi && <WizardQuestionView
                                key={currentQuestion.id}
                                question={currentQuestion}
                                onAnswerChange={(answer)=> {console.log('from within', {answer}); onAnswerChange(answer);}}
                                answer={questionnaire[currentQuestion.id] || ''}
                                contactsValue={contactsWizard[currentQuestion.contact_field] || ''}
                            />}
                            {currentQuestion && currentQuestion.is_multi && <WizardMultipleQuestionView
                                question={currentQuestion}
                                onAnswerChange={(answer)=> {console.log('from within', {answer}); onAnswerChange(answer);}}
                                contactsValue={contactsWizard || {}}
                            />}
                            {currentQuestion && <div className={classes.flexGrow} />}
                            { !bottomShowWhenScrool && status !== 'loading' &&
                                <div >
                                    <ArrowDropDown className='arrowAnimation' fontSize={"large"} color={"primary"}/>
                                    <ArrowDropDown className='arrowAnimation arrow2' fontSize={"large"} color={"primary"}/>
                                </div>
                            }
                            <div style={{ height: 10}} ref={lastElement}></div>

                        </div>


                    <div className={classes.buttonBlock}>
                        {currentQuestion && <div className={classes.flexGrow} />}
                        {currentQuestion && <div className={classes.buttonsContainer}>
                            {!RTL &&
                                <WhiteStyledButton className={classes.button} disabled={currentQuestionIndex === 0}
                                                   onClick={handleBackClick}>{backString}
                                </WhiteStyledButton>}

                            <StyledButton
                                disabled={(nextDisabled && !enableNextIfNotSelectedPartyHack) || !bottomShowWhenScrool}
                                className={classes.button}
                                onClick={handleNextClick}>{(currentQuestionIndex + 1 === questions.length) ? finishString : nextString}
                            </StyledButton>

                            {RTL &&
                                <WhiteStyledButton className={classes.button}
                                                   onClick={handleBackClick} disabled={currentQuestionIndex===0}>{backString}
                                </WhiteStyledButton>}
                            {currentQuestion && !currentQuestion.is_multi && !currentQuestion.mandatory && currentQuestionIndex + 1 !== questions.length &&
                                <StyledButton onClick={handleNextClick} className={classes.button}>{skipString}</StyledButton>}

                        </div>}
                        <div className={classes.poweredRow}>
                            <Typography className={classes.powered}>Powered by </Typography><Link className={classes.poweredLink} to='/'>LegalUp.me</Link>
                        </div>
                    </div>

                </div>

                { filteredDataMapWizard && filteredDataMapWizard.length && !isMobile && !forAddin ?
                    <div className={RTL ? classes.rightSide : classes.rightSide + ' ' + classes.rightSideOrientLeft}>
                        <div className='mapWizardScroll'>
                            { filteredDataMapWizard.map((item, index) => (
                                <div key={item.id} className={classes.mapWizardInline}>
                                    <div className={classes.mapWizardInlineLeft}>
                                        { checkQuestionNext(item, index) ?
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <ellipse cx="9.5" cy="9.5" rx="9.42188" ry="9.49219" fill="#C4C4C4"/>
                                            </svg> :
                                            <svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12.4982" cy="12.4982" r="9.42007" fill="#16C612" stroke="#16C612" stroke-opacity="0.18" stroke-width="4.1867"/>
                                                <path d="M10.6536 14.3159L8.45829 12.2578L7.51562 13.1416L10.6536 16.0834L17.125 10.0166L16.1823 9.13281L10.6536 14.3159Z" fill="white"/>
                                            </svg>
                                        }
                                    </div>
                                    <div className={classes.mapWizardInlineLeftAfter}></div>
                                    <div
                                        onClick={() => handleClickMapWizard(item, checkQuestionNext(item, index))}
                                        className={ checkQuestionNext(item, index) ? classes.mapWizardDisable : classes.mapWizardActive }
                                    >
                                        { item.question_map_title }
                                    </div>

                                </div>
                            )) }
                    </div>
                    </div> : null
                }
            </div>

            <DocumentReadyDialog 
                questionnaire={questionnaire}
                open={documentReadyDialogOpen && fileDownloadLink !== '' && fileDownloadLink !== undefined} 
                onClose={async () => {
                    setDocumentReadyDialogOpen(false);
                    dispatch(Actions.resetQuestionnaire());
                }}
                onDownload={() => {
                    window.open(fileDownloadLink, '_blank');
                    setDocumentReadyDialogOpen(false);
                }}
                onSign={() => {
                    // let newWindow = window.open(fileSignLink, 'Docusign Login', 'scrollbars=yes,width=640, height=480');
                    // let timer = setInterval(() => { 
                    //     if (newWindow.closed) {
                    //         clearInterval(timer);
                    dispatch(Actions.signFile());
                    alert(afterSignText);
                    //     }
                    // }, 1000);                    
                }
            }
            on_finish_target_origin={document?.on_finish_target_origin || ''}
            />
        </div>
    );
}