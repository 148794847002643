import React, { useState,  useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import  { useParams, useHistory  } from 'react-router-dom';
import * as Actions from "../redux/actions";
import { LinearProgress } from  '@material-ui/core';
import Header from "./Header";

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import {isMobile} from "react-device-detect";
import api from "../api";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

// make the drawer width bigger or smaller, depends on the max text field length
const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    mainContainer:{
        margin: 'auto',
        width: isMobile ? '320px' : '600px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch'
    },
    documentsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '320px' : '600px',
        height: '30vh',
        // flexGrow: 1,
        backgroundColor: 'white',
        margin: 'auto',
        marginTop: '160px'
    },
    thumbUpIcon: {
        borderRadius:'50%',
        background:'#37c53a',
        width: '120px',
        display: 'inline-flex',
        height:' 120px',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:'20px',
    },
    align: {
        textAlign: 'center',
        marginTop: '20px',
    },
}));

export default function MainDocumentSignView() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const loadingPhase = useSelector(state => state.questionnaire.loadingPhase);
    const fileId = useSelector(state => state.questionnaire.fileId);
    const signError = useSelector(state => state.questionnaire.signError);
    const { questionnaireId } = useParams();
    let fileError = '';

    if (!fileId){
        fileError = 'Wizard ID is not correct or Wizard not completed';
    }

    useEffect(() => {
        dispatch(Actions.openQuestionnaire(questionnaireId));

    }, [dispatch]);

    useEffect(() => {
        if(fileId){
            dispatch(Actions.signFileById(fileId));
        }

    }, [fileId]);

    return (
        <div className={classes.mainContainer}>
            <Header />
            <div className={classes.documentsContainer}>
                { loadingPhase === 'fetching' && <LinearProgress style={{width: '100%'}} color="primary" /> }
                {loadingPhase !=='fetching' && !fileId && fileError}


                {fileId && signError === '' && loadingPhase !=='fetching'  ?
                    (
                        <div className={classes.align}>
                            <div>The document has been emailed to the signer</div>
                            <div className={classes.thumbUpIcon}>
                                <ThumbUpIcon htmlColor='#fff' fontSize="large"></ThumbUpIcon>
                            </div>
                        </div>
                    ) : signError
                 }


            </div>
        </div>
    );
}