import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Actions from '../redux/actions';

const useStyles = makeStyles(theme => ({
    icon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
}));

function DropdownAutocomplete() {
    const contacts = useSelector(state => state.main.contacts) || [];
    const filter = createFilterOptions();
    const saveContact = useSelector(state => state.main.saveContact);
    const [arrContacts, setArrContacts] = React.useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (contacts?.length) {
            let newArr = [];
            for (let i = 0; i < contacts.length; i++) {
                if(contacts[i].full_name !== undefined){
                    newArr[i] = { id: contacts[i].id, text: contacts[i].full_name }
                }
            }
            if ( saveContact?.full_name !== null && saveContact?.full_name?.trim() !== '' ){
                const findInArr = newArr.find(e => e.text.toLowerCase().trim() === saveContact.full_name.toLowerCase().trim() );
                if (findInArr === undefined){
                    newArr.push({
                        id: saveContact.id,
                        text: saveContact.full_name,
                    });
                }
            }
            setArrContacts(newArr);
        } else if (arrContacts.length) {
            setArrContacts([{ id: saveContact.id, text: saveContact.full_name }]);
        }
    }, [dispatch, contacts])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(saveContact.full_name !== null){
                dispatch(Actions.getContacts(0, 20,  saveContact.full_name ));
            } else {
                setArrContacts([]);
                dispatch(Actions.getContacts(0, 20, '' ));
            }
        }, 1)

        return () => clearTimeout(delayDebounceFn)
    }, [dispatch, saveContact.full_name])

    useEffect(() => {
        if( saveContact.full_name !== null && saveContact.id === undefined && saveContact.new  ){
            dispatch(Actions.createContact({ full_name: saveContact.full_name } ));
        }
    }, [ saveContact.new ])

    const renderOption = (option) => {
        if(option.id === undefined){
            return `<div class="wrapperAutocompleteOption">
                        <span class="left-new">${option.text}</span>
                        <span class="right-new">Add new</span>
                    </div>`;
        } else {
            return option.text;
        }
    }

    const classes = useStyles();
    //console.log('arrContacts', arrContacts)
    return (
        <Autocomplete
            className={classes.select}
            classes={{
                clearIndicatorDirty: classes.icon,
                popupIndicator: classes.icon,
            }}
            value={saveContact}
            onChange={(event, newValue) => {
                if (newValue === null){
                    dispatch(Actions.saveContactInMemory(null, null, false));
                } else {
                    dispatch(Actions.saveContactInMemory(newValue.id, newValue.text, true));
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.text);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        id: options.id,
                        text: inputValue,
                    });
                    if(arrContacts.length !==0){
                        dispatch(Actions.saveContactInMemory(options.id, inputValue, null));
                    }
                }
                return filtered;
            }}
            id="full_name"
            options={arrContacts}
            getOptionLabel={(option) => {
                if( option.full_name !== null ){
                    return option.full_name;
                }
            }}
            renderOption={(option) => (
                <div dangerouslySetInnerHTML={{ __html: renderOption(option) }} />
            )}
            renderInput={(params) => (
                <TextField
                    {...params }
                    label="Select contact or type new"
                    variant="standard"
                />
            )}
        />
    );
}
export default DropdownAutocomplete;