import React, {useState, useContext} from 'react';
import {Redirect} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, LinearProgress, CssBaseline, Avatar, TextField, Button, Container} from '@material-ui/core';
import Api from '../api';

import GavelIcon from "@material-ui/icons/Gavel";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '12px',
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    withBlocks:{
        width: '320px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    marginBottom: {
        marginBottom: '30px',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textField: {
        paddingBottom: '22px'
    },
    title: {
        fontFamily: 'Assistant',
        color: '#4789d2',
        margin: '12px 0px'
    },
    text: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        margin: '12px 12px'
    },
    link: {
        fontFamily: 'Assistant',
        fontSize: '10px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#4789d2',
        marginTop: '8px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'Assistant',
        fontSize: '10px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        alignItems: 'center'
    },
    progress: {
        width: '74%',
        margin: 'auto'
    },
    margin: {
        marginTop: '20px',
    },
    error: {
        color: 'red',
    }
}));

export default function ForgotPasswordView(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(undefined);
    const [token, setToken] = useState(undefined);
    const [error, setError] = useState();
    const [password, setPassword] = useState(undefined);
    const [phase, setPhase] = useState(undefined);
    const [redirect, setRedirect] = useState(undefined);
    const handleClick = async () => {
        try {
            await Api.users.forgotPassword(email);
            setPhase('reset');
        } catch (e) {
            setError(e)
            console.error(e);
        }
    }
    const resetClick = async () => {
        await Api.users.resetPassword(email, token, password);
        setPhase('done');
    }
    const handleLoginClick = async () => {
        setRedirect('/login');
    };

    return redirect ? <Redirect to={redirect} /> : (

         <Container component="main">
             <CssBaseline />
             <div className={classes.paper}>
                 <div className={classes.withBlocks}>
                     <Avatar className={classes.avatar}>
                         <GavelIcon />
                     </Avatar>

                     {phase === undefined  && <div className={classes.root}>
                         <Typography variant="h4" className={classes.marginBottom}>
                             Forgot Password
                         </Typography>
                         <TextField
                             variant="outlined"
                             margin="normal"
                             required
                             fullWidth
                             type="email"
                             label="Email Address"
                             name="email"
                             autoComplete="email"
                             autoFocus

                             onChange={(e)=> {setEmail(e.target.value);}}
                         />

                         <Typography className={classes.text}>Clicking the button will send you a pin to your email address</Typography>


                         <Button
                             type="submit"
                             fullWidth
                             variant="contained"
                             color="primary"
                             className={classes.submit}
                             disabled={email === undefined}
                             onClick={handleClick}
                         >
                             Send Pin
                         </Button>

                         {error &&


                         <Typography className={classes.error}>Check the correctness of the entered Email</Typography>
                         }

                     </div>}

                     { phase === 'reset' && <div className={classes.root}>

                         <Typography variant="h4" className={classes.marginBottom}>
                             Reset Password
                         </Typography>

                         <TextField
                             variant="outlined"
                             margin="normal"
                             required
                             fullWidth
                             type='number'
                             label="token"
                             name="token"
                             onChange={(e)=> {setToken(e.target.value);}}
                         />

                         <TextField
                             variant="outlined"
                             margin="normal"
                             required
                             fullWidth
                             type='password'
                             label="New password"
                             name="password"
                             onChange={(e)=> {setPassword(e.target.value);}}
                         />

                         <Typography className={classes.text}>Please copy and paste the pin we've sent you onto the token box and choose a new password to reset.</Typography>

                         <Button
                             type="submit"
                             fullWidth
                             variant="contained"
                             color="primary"
                             onClick={resetClick}
                         >
                             Reset
                         </Button>

                     </div>}
                     {phase === "done" && <div className={classes.root}>

                         <Typography variant="h4">
                             Your password has been reset
                         </Typography>
                         <Button
                             className={classes.margin}
                             type="submit"
                             fullWidth
                             variant="contained"
                             color="primary"
                             disabled={email === undefined}
                             onClick={handleLoginClick}
                         >
                             Login
                         </Button>
                     </div>}
                 </div>
             </div>
            {isLoading && <LinearProgress className={classes.progress} />}
        </Container>

    );
}
