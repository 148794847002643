import React, {useState, useContext, useEffect} from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom';
import Api from '../api';
import LoggedInUserContext from '../loggedInUserContext';
import ErrorIcon from '@material-ui/icons/Error';
import GavelIcon from '@material-ui/icons/Gavel';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, LinearProgress, Avatar, CssBaseline, Container} from '@material-ui/core';
import Logo from "./Logo";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper2: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: '6px 40px',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        margin: '18px 40px'
    },
    row: {
        width: '100%',
        textAlign: 'right',
    },
    withBlocks:{
        width: '320px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        color: '#2196f3',
    },
    rowCenter: {
        width: '100%',
        textAlign: 'center',
        marginBottom: '20px',
    },
    footer: {
        marginTop: '0',
        background: 'rgba(33, 150, 243, 0.05)',
        lineHeight: '80px',
        display: 'block',
        width: '100%',
        height: '80px'
    },
    container: {
        minHeight: 'calc(100vh - 144px)',
    },
    error: {
        display: 'flex',
        position: 'relative',
        padding: '10px 8px',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        boxSizing: 'border-box',
        background:' rgba(255, 89, 89, 0.15)',
        borderRadius: '4.2px 4.2px 1px 1px',
        border: '1px solid rgba(255, 89, 89, 0.1)',
    },
    errorIcon: {
        marginRight: '8px',
    },
    closeError: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: '2',
        cursor: "pointer",
    },

}));

export default function SignIn() {
    const classes = useStyles();
    // const [email, setEmail] = useState(undefined);
    // const [password, setPassword] = useState(undefined);
    const [fields, setFields] = useState({});    
    const [showError, setShowError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);
    const [redirectSso, setRedirectSso] = useState(false);
    const [redirectLink, setRedirectLink] = useState('');
    let userContext = useContext(LoggedInUserContext);
    const history = useHistory();

    const onChange = e => {
        const newFields = Object.assign({}, fields, {[e.target.name]: e.target.value});
        setFields(newFields);
    };

    const onCloseError = e => {
        setShowError(undefined);
    };


    // when working with server use this
    const handleClick = async (e) => {
        try {
            await setIsLoading(true);
            const user = await Api.users.authenticate(fields.email, fields.password);
            await userContext.update(user.id);
            await setRedirectHome(true);
            setShowError(undefined)
        }
        catch(e) {
            setShowError('Incorrect email address or password');
            console.error(e);
        } finally {
            await setIsLoading(false);
        }
    };

    const handleClickSso = async (e) => {
        try {
            await setIsLoading(true);
            const link = await Api.users.getSsoLink();
            if(link) {
                setRedirectLink(link);
                setRedirectSso(true);
                setShowError(undefined)
            }
        }
        catch(e) {
            setShowError('Incorrect request');
            console.error(e);
        } finally {
            await setIsLoading(false);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const codeSso = urlParams.get('codeSso');

        const authenticateUser = async () => {
            if (codeSso) {
                const code = JSON.parse(codeSso);

                try {
                    await setIsLoading(true);
                    const user = await Api.users.authenticateSso(code);
                    await userContext.update(user.id);
                    await setRedirectHome(true);
                    setShowError(undefined);
                    history.push('/');
                } catch (e) {
                    setShowError('Incorrect Sso email address');
                    console.error(e);
                } finally {
                    await setIsLoading(false);
                }
            }
        };

        authenticateUser();
    }, []);

    if (redirectSso && redirectLink) {
        window.location.href = redirectLink;
    }

  return redirectHome ? (<Redirect to='/' />) : (
      <>
        <Container className={classes.container} component="main">
          <CssBaseline />
          <div className={classes.paper}>
              <div className={classes.withBlocks}>
                  <div>
                      <Typography variant="h4" className='login-page'>
                          <Logo />
                      </Typography>
                  </div>

                  {
                      showError ?
                          <div className={classes.error}>
                              <ErrorIcon htmlColor='rgba(255, 89, 89, 0.15)' className={classes.errorIcon}/>
                              {showError}
                              <svg className={classes.closeError} onClick={onCloseError} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.25006 2.15937L5.34069 1.25L3.75006 2.84063L2.15944 1.25L1.25006 2.15937L2.84069 3.75L1.25006 5.34062L2.15944 6.25L3.75006 4.65937L5.34069 6.25L6.25006 5.34062L4.65944 3.75L6.25006 2.15937Z" fill="#E51111"/>
                              </svg>


                          </div> : null
                  }

                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={fields.email}
                      onChange={onChange}
                  />
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="password"
                      name="password"
                      label="Password"
                      autoComplete="current-password"
                      onChange={onChange}
                      value = {fields.password}
                  />
                  <div className={classes.row}>
                      <Link to='/forgotPassword' className={classes.link}>Forgot Password</Link>
                  </div>

                  <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleClick}
                  >
                      Log In
                  </Button>



              </div>

          </div>

            {isLoading && <LinearProgress className={classes.progress} />}

            <div className={classes.rowCenter}>
                <a href='https://app.legalup.me/login' className={classes.link}>Log in to LegalUp's old automation system instead</a>
            </div>

            <div className={classes.paper2}>
                <div className={classes.withBlocks}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleClickSso}
                    >
                        Log In via SSO
                    </Button>
                </div>
            </div>

        </Container>
      <div className={classes.footer}>
          <div className={classes.rowCenter}>
            Don’t have a LegalUp account? <a href='https://legalup.me/contact/' className={classes.link}>Contact us now</a>
          </div>
      </div>
    </>
  );
}