import React from 'react';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ReactComponent as LegalupLogo } from '../assets/ic_logo.svg';


const useStyles = makeStyles(theme => ({
    mainHeader: {
        backgroundColor: '#FFFFFF'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    grow: {
        // flexGrow: 1
    },
    firstText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#35373d'
    },
    secondText: {
        fontWeight: '300'
    },
    image: {
        width: '24px',
        height: '24px',
        paddingRight: '16px'
    },
    separator: {
        marginLeft: '16px',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#35373d'
    },
    title: {
        margin: '0px 16px',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#35373d'
    }
}));
    
    function Logo(props) {
        const classes = useStyles();
        const {title} = props;
        return (
            <div className={classnames(classes.grow, classes.row)} {...props}>
                <LegalupLogo className={classes.image} />
                <div className={classes.row}>
                    <Typography className={classes.firstText}>Legal</Typography>
                    <Typography className={classnames(classes.firstText, classes.secondText)}>Up</Typography>
                    {title && <Typography className={classes.separator}>|</Typography>}
                    {title && <Typography className={classes.title}>{title}</Typography>}
                </div>
            </div>
        );
    }
    export default Logo;