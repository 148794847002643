import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Link, LinearProgress, Button, InputBase, TableRow, TableCell, CircularProgress, IconButton } from  '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as Actions from '../redux/actions';

import HeaderForStatistics from "./HeaderForStatistics";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles(theme => ({
    main:{
        background: 'rgba(33, 150, 243, 0.05)',
        width: '100%',
        height: '100vh',
    },
    root: {
        margin: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        // maxHeight: '400px'
        maxHeight: '80vh',

    },
    flexGrow: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    rightContainer: {
        width :'20px',
        flexGrow: 0,
        backgroundColor: 'white'
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    actionLink: {
        margin: '0px 12px',
        cursor: 'pointer'
    },
    actionIcon: {
        width: '12px',
        height: '12px',
        marginLeft: '12px'
    },
    actionButton: {
        width: '12px',
        height: '12px',
    },
    fullRow: {
        width: '100%'
    },
    footer_for_statistics: {
        marginTop: '-50px',
        display:"block",
        position: 'absolute',
        bottom: '5px',
    },
    total: {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        marginRight: '20px',
    }
}));

export default function DocumentList() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const documents = useSelector(state => state.main.documentsStatistics);
    const documentsStatisticsDate = useSelector(state => state.main.documentsStatisticsDate);
    const countStatistics = useSelector(state => state.main.countStatistics);
    const [currUser] = useState((localStorage.getItem("current_user") && JSON.parse(localStorage.getItem("current_user")))|| undefined);
    const docsCount = useSelector(state => state.main.docsCount);
    const loadingPhase = useSelector(state => state.main.loadingPhase);
    const [page, setPage] = useState(1);

    const columns = [{
        name: "name",
        label: "MasterDoc Name",
        options: {
            filter: true,
            sort: true,
        }
    }, {
        name: "updated_at",
        label: "Updated At",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return moment.utc(value*1000).local().format('llll');
            },
        }
    }, {
        name: 'created',
        label: 'Created',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, iTableMeta, updateValue) => {
                let statisticQuestionnairesCount = documents[iTableMeta.rowIndex].statistic.questionnairesCount;
                return <div className={classes.actionsContainer}>
                    <span>{statisticQuestionnairesCount}</span>
                </div>
            }
        }
    }, {
        name: 'finished',
        label: 'Finished',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, iTableMeta, updateValue) => {
                let completedQuestionnairesCount = documents[iTableMeta.rowIndex].statistic.completedQuestionnairesCount;
                return <div className={classes.actionsContainer}>
                         <span>{completedQuestionnairesCount}</span>
                     </div>
            }
        }
    }];

    useEffect(() => {
        const loadStuff = async () => {
            await dispatch(Actions.getDocumentsForStatistics(page - 1, 20, documentsStatisticsDate.startDate,documentsStatisticsDate.endDate ));
        };
        loadStuff();
    }, [dispatch, page, documentsStatisticsDate]);


    useEffect(() => {
        const loadStuff = async () => {
            if (currUser) {
                if (currUser['organization_id'] !== undefined) {
                    await dispatch(Actions.getDocumentsForStatisticsCount(currUser['organization_id'], documentsStatisticsDate.startDate, documentsStatisticsDate.endDate));
                }
            }
        }
        loadStuff();

    }, [dispatch, documentsStatisticsDate, currUser]);

    const options = {
        filter: false,
        page: page - 1,
        rowsPerPage: 20,
        rowsPerPageOptions: [20],
        download: false,
        print: false,
        select: false,
        search: false,
        pagination: true,
        customToolbar: null,
        selectableRows: false,
        sort: true,
        serverSide: true,
        viewColumns: false,
        expandableRows: false,
        expandableRowsOnClick: false,
        count: docsCount,

        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
            <TableFooter className='footer_statistic'>
                <TableRow>
                    <TableCell align={"right"} >
                        <span className={classes.total}>
                            {`Total Created: ${countStatistics.questionnairesCount} / Total Finished: ${countStatistics.completedQuestionnairesCount}` }
                        </span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TablePagination
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={async(_, p) =>{
                            setPage(p+1)
                            changePage(p)
                            }
                        }
                        onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
                        rowsPerPageOptions={[]}
                    />
                </TableRow>
            </TableFooter>
        ),
    };

    return(
        <div className={classes.main}>
            <div className={classes.root}>
                <HeaderForStatistics />
                    {loadingPhase === 'fetching' && <LinearProgress style={{width: '100%'}} color="primary" />}
                    <MUIDataTable
                        className={classes.flexGrow}
                        data={documents}
                        columns={columns}
                        options={options}
                    />
                    <div className={classes.rightContainer}></div>
            </div>
        </div>
       );
}
