import React, {useEffect} from 'react';
import api from '../api';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {Select, Typography, Checkbox, FormGroup, FormControl, FormHelperText, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import StyledTextField from './StyledTextField';
// import StyledSelect from './StyledSelect';
import StyledMenuItem from './StyledMenuItem';
import {makeRTL, isRTL} from './utils';
import {isMobile} from 'react-device-detect';
import * as Actions from "../redux/actions";
import {useDispatch} from "react-redux";

import { NumericFormat } from 'react-number-format';

const useStyles = makeStyles({
    mainContainer:{
        width: isMobile ? '320px' : '600px',
        // margin: 'auto',
        backgroundColor: 'white',
        // margin: '52px 32px 28px 32px',        
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch'
    },
    title: {
        fontFamily: 'Assistant',
        fontSize: '22px',
        letterSpacing: '0px',
        color: '#35373D',
        // margin: '3px 32px',
        marginTop: '12px',
        // marginBottom: '6px',
        marginLeft: '32px',
        marginRight: '32px',
        fontWeight: '700'

    },
    text: {
        fontFamily: 'Assistant',
        fontSize: '16px',
        margin: '-4px 32px 0px 32px',
        fontWeight: '700',
        color: '#777777',
        paddingTop: '10px',
    },
    explanation: {
        fontFamily: 'Assistant',
        fontSize: '12.5px',
        color: '#777777',
        fontStyle: 'italic',
        margin: '0px 32px',
        paddingTop: '5px',
    },
    helperText: {
        padding: '0px 32px'
    },
    alignRight: {
        textAlign: 'right'
    },
    select: {
        // width: '155px',
        marginLeft: '32px',
        marginRight: '32px',
    },
    radio: {
        padding: '0px 32px'
    },
    formControl: {
        margin: '0px 32px'
    },
    validationError: {
        color: 'red',
        marginLeft: '32px',
        marginRight: '32px'
    },
    fieldWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputBase: {
        direction: 'ltr'
    },
    rtlInputBase: {
        direction: 'rtl'
    },
    formLabel: {
        margin: '2px 0px 2px 0px',
    },
    asterisk: {
        color: '#f50057',
    },
    hide: {
        color: 'rgba(0,0,0,0)!important',
    },
    size: {
        color: '#35373D',
        fontSize: '16px',
    }
});

const questionTypeToInputType = qType => {
    return (['email', 'number', 'date'].indexOf(qType) >= 0) ? qType : 'text';
}

export default function WizardQuestionView({question, onAnswerChange, answer, parentAnswer, contactsValue, childAnswers = false, partyQuestion = {} }) {
    const classes = useStyles();
    const RTL = isRTL(question.title);
    const dispatch = useDispatch();

    useEffect(() => {
        if (contactsValue && !childAnswers) {
            answer = {
                question_id: question.id,
                value: (answer && answer.value) || contactsValue.trim()
            };
            dispatch(Actions.saveAnswer(answer));
        }

        else if(!contactsValue && !childAnswers && question.default_value && question.default_value !==''){
            answer = {
                question_id: question.id,
                value: (answer && answer.value) || question.default_value
            };
            dispatch(Actions.saveAnswer(answer));
        }
    }, [contactsValue]);

    const anotherLabel = RTL ? 'אחר' : 'Other';
    const linkInText = RTL ? 'לחצו כאן' : 'click here';
    let val = (answer && answer.value) || ( contactsValue.includes('|') ? '' :  contactsValue.trim() ) || '';
    const other = (answer && answer.other_value) || '';
    const SEPARATOR = '•';
    const OTHER_VALUE_ENCODING = 'OTHER_VALUE_ENCODING';
    const mandatory = question.mandatory;

    const text = question.text;
    const explanationText = question.explanation_text;
    let newText = '';
    let newTextForExplanation = '';

    let valWithoutSpace = []
    if( val && val.indexOf(SEPARATOR) !== -1 && 'checkboxes' === question.type ){
        val.split(SEPARATOR).map((v)=>{
            valWithoutSpace.push(v.trim());
        })
    } else if (val && 'checkboxes' === question.type) {
        val = val.trim();
    }

    if(valWithoutSpace.length){
        val = valWithoutSpace.join(SEPARATOR);
    }

    if (text && text.includes('http') ) {
        let textArr = text.split(' ');
        for (let i = 0; i < textArr.length; ++i) {
            if(textArr[i].includes('http')){
                const link = ` <a target='_blank' href = '${textArr[i]}'>${linkInText}</a> `;
                newText += `${link} `;
            } else {
                newText += `${textArr[i]} `;
            }
        }
    } else {
        newText = question.text;
    }

    if (explanationText && explanationText.includes('http') ) {
        let textArr = explanationText.split(' ');
        for (let i = 0; i < textArr.length; ++i) {
            if(textArr[i].includes('http')){
                const link = ` <a target='_blank' href = '${textArr[i]}'>${linkInText}</a> `;
                newTextForExplanation += `${link} `;
            } else {
                newTextForExplanation += `${textArr[i]} `;
            }
        }
    } else {

        newTextForExplanation = question.explanation_text;
    }



console.log('--------------------contactsValue---------------------------', contactsValue)
console.log('--------------------val---------------------------', val)
console.log('--------------------answer---------------------------', answer)

    const onCheckBoxChange = async (innerVal, val) => {
        let newVal = val.trim().includes(SEPARATOR) ? val.trim().split(SEPARATOR) : (val ? [val.trim()] : []);

        const existsInNewVal = newVal.some(v => v.trim() === innerVal.trim());

        if (existsInNewVal) {
            newVal = newVal.filter(v => v.trim() !== innerVal.trim());

            if (newVal.length === 0) {
                newVal.push(innerVal.trim());
            }
        } else {
            newVal.push(innerVal.trim());
        }

        onAnswerChange({
            question_id: question.id,
            value: newVal.join(SEPARATOR).toString(),
            other_value: other
        });
    };

    const asterisktext = `<span class=${classNames(classes.asterisk)} >*</span>`
    const calculatedText = `${newText ? newText : ''} ${mandatory ? asterisktext : ''} ${answer && answer.valid !== undefined && !answer.valid ? `(${answer.error})`: ''}`;

    const ddmmyyyyToyyyymmdd = val => {
        const splitted = val.split('/');
        return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
    };
    const yyyymmddToddmmyyyy = val => {
        const splitted = val.split('-');
        return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
    };
    // console.log({val}, OTHER_VALUE_ENCODING);

    const checkRtl = val => {
        return isRTL(val);
    }

    const handleFileChange = async (question_id, file) => {
        return api.questionnaires.uploadFile(question_id, file);
    }

    const q = <div className={classes.mainContainer}>
        <Typography className={classNames(classes.title)}>{question.title}</Typography>

        {calculatedText && calculatedText.trim()!=='' &&
            <Typography className={classNames(classes.text)}>
                <span dangerouslySetInnerHTML={{ __html:calculatedText }}></span>
            </Typography>
        }

        { newTextForExplanation &&
            <Typography className={classNames(classes.explanation)}>
                <span dangerouslySetInnerHTML={{ __html:newTextForExplanation }}></span>
            </Typography>
        }

        {[ 'email', 'address'].indexOf(question.type) >= 0 &&
        <div className={classes.fieldWrapper}>
            <StyledTextField
                placeholder={question.hint_text || ''}
                value={val}
                onChange={async e => {
                    onAnswerChange({ question_id: question.id, value: e.target.value });
                }}
                InputProps={{
                    classes: { input: RTL ? classes.rtlInputBase : classes.inputBase}
                }}
                type={questionTypeToInputType(question.type)}
            />
            {!answer.valid && <Typography className={classes.validationError}>{answer.error}</Typography>}
        </div>
        }

        {question.type === 'attachment'  &&
            <div className={classes.fieldWrapper}>
                <StyledTextField
                    onChange={async e => {
                        const fileObj =e.target.files[0]
                            e.target.value = null
                        handleFileChange(question.id, fileObj)
                            .then((res)=>{
                                onAnswerChange({ question_id: question.id, value: res.id });
                            })
                    }}
                    InputProps={{
                        classes: { input: RTL ? classes.rtlInputBase +' '+ classes.hide : classes.inputBase +' '+ classes.hide}
                    }}
                    disabled={!!val}
                    title=' '
                    type='file'
                    className = {classes.hide}
                />
                { val !== '' ?
                    <Typography className={classNames(classes.explanation + ' '+ classes.size)}>
                        File uploaded
                    </Typography>
                    : '' }
                {!answer.valid && <Typography className={classes.validationError}>{answer.error}</Typography>}
            </div>
        }

        {question.type === 'number'  && <div className={classes.fieldWrapper}>
            <NumericFormat
                placeholder={question.hint_text || ''}
                value={val ? Number(val) : ''}
                className={ RTL ? classes.rtlInputBase : classes.inputBase}
                allowLeadingZeros
                thousandSeparator=","
                onValueChange={ async e => {
                    onAnswerChange({ question_id: question.id, value: e.value });
                }}
                customInput={StyledTextField}
            />
            {!answer.valid && <Typography className={classes.validationError}>{answer.error}</Typography>}
        </div>
        }

        {question.type === 'date' && <div className={classes.fieldWrapper}><StyledTextField
            placeholder={question.hint_text || ''}
            // defaultValue={question.default_value || ''} 
            value={val && ddmmyyyyToyyyymmdd(val) || question.default_value || ''}
            onChange={e => {
                onAnswerChange({ question_id: question.id, value: yyyymmddToddmmyyyy(e.target.value) });
            }}
            InputProps={{
                classes: { input: classes.inputBase}
            }}
            type={questionTypeToInputType(question.type)}
        />{!answer.valid && <Typography className={classes.validationError}>{answer.error}</Typography>}
        </div>}
        {['text_box'].indexOf(question.type) >= 0 && <div className={classes.fieldWrapper}><StyledTextField
            placeholder={question.hint_text ||''}
            value={val || question.default_value || ''}
            onChange={(e) => { onAnswerChange({ question_id: question.id, value: e.target.value })}}
            multiline
            rowsMax={question.num_lines}
            type={questionTypeToInputType(question.type)}
        />
        </div>}
        {'drop_down' === question.type &&
            <Select className={classes.select}  style={{ direction: checkRtl(val) ? 'rtl' : 'ltr' }}
                placeholder={question.hint_text} defaultValue ={question.default_value} value={val} onChange={e =>
            {onAnswerChange({ question_id: question.id, value: e.target.value });}}
            >
            {question.possible_answers && question.possible_answers.map((val, idx) => (
                <StyledMenuItem style={{ direction: checkRtl(val) ? 'rtl' : 'ltr' }}
                    dense
                    value={val.trim()}
                    key={`${val}${idx}`}>{val}
                </StyledMenuItem>
            ))}
            {question.add_other && <StyledMenuItem style={{ justifyContent: RTL ? 'flex-end': 'flex-start' }}
                dense
                value={OTHER_VALUE_ENCODING}
                key={`other`}>{RTL ? 'אחר' : 'Other'}</StyledMenuItem>}
        </Select>}
        {val === OTHER_VALUE_ENCODING && <div><br /><StyledTextField
            value = {other}
            style={{width:'510px'}}
            placeholder={anotherLabel}
            disabled={val !== OTHER_VALUE_ENCODING}
            onChange={e => {
                onAnswerChange({
                    question_id: question.id,
                    value: val,
                    other_value: e.target.value
                });
            }}
        /></div>}

        {'radio_buttons' === question.type && <FormControl component="fieldset">
            <RadioGroup value={val} onChange={e=>{onAnswerChange({ question_id: question.id, value: e.target.value });}} className={classes.radio} >
            {question.possible_answers && question.possible_answers.map((val, idx) => (
                <FormControlLabel key={idx} value={val.trim()} style={{ direction: checkRtl(val) ? 'rtl' : 'ltr' }} control={<Radio />} label={val} />
            ))}
            {question.add_other && <div className={classes.addOther}><FormControlLabel
                onChange={async e => { await onAnswerChange({ question_id: question.id, value: e.target.value }); }}
                key={'other'}
                value={'other'}
                control={<Radio />}
                label={RTL ? 'אחר' : 'Other'} />
                <br />
                <StyledTextField
                    value = {other}
                    style={{width:'510px'}}
                    placeholder={anotherLabel}
                    disabled={val.toLowerCase() !== 'other'}
                    onChange={e => {
                        onAnswerChange({
                            question_id: question.id,
                            value: val,
                            other_value: e.target.value
                        });
                    }}
                />
            </div>}
            </RadioGroup>
        </FormControl>
        }
        {'checkboxes' === question.type &&
            <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
                {question.possible_answers && question.possible_answers.map((v, idx) => (
                    <FormControlLabel className={classes.formLabel} key={idx} control={<Checkbox
                        checked={val.split(SEPARATOR).indexOf(v.trim()) >= 0}
                        onChange={e => {onCheckBoxChange(v.trim(), val);}}
                        value={v.trim()}
                        />}
                    label={v}
                    style={{ direction: checkRtl(v) ? 'rtl' : 'ltr' }}
                    />
                ))}
                {question.add_other && <div className={classes.addOther}>
                    <FormControlLabel className={classes.formLabel} key={question.possible_answers.length} control={<Checkbox
                        checked={val.split(SEPARATOR).indexOf(OTHER_VALUE_ENCODING) >= 0}
                        onChange={async e => {
                            onCheckBoxChange(OTHER_VALUE_ENCODING, val);
                        }}
                        value={'another'} />}
                    label={anotherLabel} />
                    <br />
                    <StyledTextField
                        value = {answer ? answer.other_value : ''}
                        style={{width:'510px'}}
                        placeholder={anotherLabel}
                        disabled={val.split(SEPARATOR).indexOf(OTHER_VALUE_ENCODING) < 0}
                        onChange={async e => {
                            await onAnswerChange({
                                question_id: question.id,
                                value: val,//.split(SEPARATOR).sort().toString(),
                                other_value: e.target.value
                            });
                        }}
                    />
                </div>}
            </FormGroup>
        </FormControl>
        }
        {question.help_text && <FormHelperText className={classNames(classes.helperText, isRTL ? 'alignRight' : '')}>{question.help_text}</FormHelperText>}
    </div>;
    return RTL ? makeRTL(q) : q;
}