import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const RTLtheme = createMuiTheme({
    direction: 'rtl',
});

export const makeRTL = object => {
    return <ThemeProvider theme={RTLtheme}>
        <div dir={"rtl"}>
            {object}
      </div>
    </ThemeProvider>
}

export const isRTL = str => {
    return /[\u0591-\u07FF]/.test(str);
};

export const ObjectId = (rnd = r16 => Math.floor(r16).toString(16)) =>
    rnd(Date.now()/1000) + ' '.repeat(16).replace(/./g, () => rnd(Math.random()*16));
