import React from 'react';
import LoggedInUserContext from  './loggedInUserContext';
import { withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
window.store = store;

class App extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            user_id: localStorage.getItem('current_user') ? JSON.parse(localStorage.getItem('current_user')).id : undefined,
            update: async (user_id) => {
                this.setState(state => Object.assign({}, state, { user_id }));
            }           
        }
    }
    async componentDidMount() {
        try {
            let currentUser = JSON.parse(localStorage.getItem('current_user'));
            if (currentUser) {
                await this.state.update(currentUser.id);
                document.cookie=`access_token=${localStorage.getItem('access_token')}`;
            }
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return <Provider store={store}>
            <LoggedInUserContext.Provider value={this.state}>
                {this.props.children}
            </LoggedInUserContext.Provider>
        </Provider>
    }
}

export default withRouter(App);
