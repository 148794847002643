import { Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
import App from './App';
import theme from './Theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LoggedInUserContext from './loggedInUserContext';
import LoginView from './components/LoginView';
import MainView from './components/MainView';
import MainDocumentView from './components/MainDocumentView';
import MainWizardView from './components/MainWizardView';
import MainDocumentSignView from './components/MainDocumentSignView';
import ContactList from './components/ContactList';
import ForgotPasswordView from './components/ForgotPasswordView';
import Statistics from './components/Statistics';
import CategoryView from './components/CategoryView';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <LoggedInUserContext.Consumer>
        {context => ( 
            <Route {...rest} render={props =>
                context.user_id ? (
                    <Component {...props} />
                ) : (<LoginView {...props} />) }
        />)}
    </LoggedInUserContext.Consumer>
);

const LegalupRouter = () => {
    return <BrowserRouter basename='/'>
        <MuiThemeProvider theme={theme}>
            <App>
                <Route exact path="/login" component={LoginView} />
                <Route exact path="/wizard/:questionnaireId/sign" component={MainDocumentSignView} />
                <Route exact path="/wizard/:questionnaireId" component={MainWizardView} />
                <Route exact path="/category/:categoryId" component={CategoryView} />
                <Route exact path="/documents/:documentId" component={MainDocumentView} />
                <Route exact path="/forgotPassword" component={ForgotPasswordView} />
                <Route exact path="/statistics" component={Statistics} />
                <PrivateRoute exact path="/" component={MainView} />
                <PrivateRoute exact path="/contacts" component={ContactList} />
            </App>
        </MuiThemeProvider>
    </BrowserRouter>;
}

export default LegalupRouter;