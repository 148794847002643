import { withStyles } from '@material-ui/core/styles';
import {MenuItem} from '@material-ui/core';

export default withStyles(theme => ({
    root: {        
        width: 'auto',
        fontFamily: 'Assistant',
        fontSize: '14px',
        height: '24px',
        '&:hover': {
            backgroundColor: '#19E5F02D'//'rgba(18, 116, 228, 0.2)',
        },
        '&.Mui-selected': {
            backgroundColor: '#19E5F0EE',//'rgba(18, 116, 228, 0.05)',
            '&:hover': {
                backgroundColor: '#19E5F0DD'//'rgba(18, 116, 228, 0.2)',
            }
        }
    },
}))(MenuItem);