import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducer';
import * as Actions from './actions';

const logger = createLogger();

const store = createStore(
    reducer,
    applyMiddleware(thunk, logger)
);

if (localStorage.getItem("current_user")) {
    const user = JSON.parse(localStorage.getItem("current_user"));
    if (user && user.id) {
        store.dispatch(Actions.refreshUser(user.id));
    }
}

export default store;
