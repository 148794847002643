import React, { useEffect, useState } from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Header from './HeaderForCategory';
import * as Actions from '../redux/actions';
import DocumentList from './DocumentList';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import DocumentListForCategory from "./DocumentListForCategory";

// make the drawer width bigger or smaller, depends on the max text field length
const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        color: 'blue'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    defaultWindow: {
        textAlign: 'center',
    },
    role: {
        marginLeft: '16px',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '400',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#35373d'
    }
}));

export default function Dashboard(props) {
    const [redirect, setRedirect] = useState(undefined);
    const { categoryId } = useParams();
    const classes = useStyles()
    const dispatch = useDispatch();
    const [currUser] = useState((localStorage.getItem("current_user") && JSON.parse(localStorage.getItem("current_user")))|| undefined);
    useEffect(() => {
        const loadStuff = async () => {
            if (currUser) {
                try {
                    if (currUser['organization_id'] !== undefined)  {
                        await dispatch(Actions.getOrganization(currUser["organization_id"]));
                    }
                    dispatch(Actions.clearDocumentsForCategory());
                    dispatch(Actions.getDocumentsForCategory(0, 50, categoryId));
                    //await dispatch(Actions.getQuestionnaires());
                } catch(err) {
                    console.log('$$$$', JSON.stringify(err));
                    if (err.toString().indexOf('expired or incorrect token') >= 0) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('current_user');
                        setRedirect('/login');
                    }
                }
            }
        };
        loadStuff();
    }, [currUser]);
    return redirect ? <Redirect to={redirect} /> : (
        <div className={classes.root}>
            <Header />
            <DocumentListForCategory />
        </div>
    );
}
